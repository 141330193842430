export default {
  /* general */

  general_title: "Les carrefours de l'entrepreneuria",
  required_field: `Champ requis`,
  general_yes: "Oui",
  general_no: "Non",

  /* side menu */

  side_menu_dashboard: "Tableau de bord",
  side_menu_beneficiaries: "Bénéficiaires",
  side_menu_users: "Utilisateurs",
  side_menu_event: "Événements",
  side_menu_tags: "File Active",

  /* login */

  login_submit: "Se connecter",
  login_username: "E-mail",
  login_password: "Mot de passe",
  login_empty_username: "Veuillez saisir votre e-mail !",
  login_empty_password: "Veuillez saisir votre mot de passe !",
  login_success: "Vous êtes connecté avec succès !",
  login_failure: "Login ou mot de passe incorrect.",
  login_forgot_password: "Mot de passe oublié",
  login_register_now: "inscrivez-vous dès maintenant !",
  login_or: "Ou",

  /* register */

  register_firstname_label: "Prénom",
  register_lastname_label: "Nom de famille",
  register_email_label: "E-mail",
  register_password_label: "Mot de passe",
  register_confirm_password_label: "Confirmer le mot de passe",
  register_phone_number_label: "Numéro de téléphone",
  register_email_not_valid: "L'e-mail n'est pas valide !",
  register_empty_firstname: "Veuillez saisir votre prénom !",
  register_empty_lastname: "Veuillez saisir votre nom de famille !",
  register_empty_email: "Veuillez saisir votre e-mail !",
  register_empty_password: "Veuillez saisir votre mot de passe !",
  register_empty_confirm_password: "Veuillez confirmer votre mot de passe !",
  register_empty_phone_number: "Veuillez saisir votre numéro de téléphone !",
  register_password_not_match:
    "Les deux mots de passe que vous avez entrés ne correspondent pas !",
  register_submit: "S'inscrire",
  register_already_have_account:
    "Vous avez déjà un identifiant et un mot de passe ?",
  register_login: "S'identifier",
  register_success: "Votre compte a été crée avec succès",
  register_failure:
    "Une erreur est survenue lors de la création de votre compte",

  /* forgot password */

  forgot_pwd_already_have_account:
    "Vous avez déjà un identifiant et un mot de passe ?",
  forgot_pwd_login: "Se connecter",
  forgot_pwd_empty_email: "Veuillez saisir votre e-mail !",
  forgot_pwd_email: "E-mail",
  forgot_pwd_reset_pwd: "Réinitialiser le mot de passe",
  forgot_pwd_success:
    "Vérifier votre e-mail pour pouvoir modifier votre mot de passe !",
  forgot_pwd_failure: "Une erreur est survenue !",

  /* auth_layout */

  auth_layout_confirm_logout:
    "Vous allez être déconnecté de l'interface, voulez-vous continuer ?",

  /* dashboard */

  dashboard_beneficiaries_firstame: "Prénom",
  dashboard_beneficiaries_lastname: "Nom de famille",
  dashboard_beneficiaries_email: "E-mail",
  dashboard_beneficiaries_phone_number: "Numéro de téléphone",
  dashboard_beneficiaries_age: "Age",
  dashboard_beneficiaries_genre: "Genre",
  dashboard_beneficiaries_man: "Homme",
  dashboard_beneficiaries_woman: "Femme",
  dashboard_beneficiaries_filters: "Filtres",
  dashboard_beneficiaries_filters_beneficiaries: "Bénéficiaire",
  dashboard_beneficiaries_filters_project: "Projet",
  dashboard_beneficiaries_filters_fil_active: "File Active",
  dashboard_beneficiaries_filters_orientations: "Orientation",
  dashboard_beneficiaries_filters_level_study: "Niveau d'étude",
  dashboard_beneficiaries_filters_labour_market_situation:
    "Situation sur le marché du travail",
  dashboard_beneficiaries_filters_handicapped_obstacles: "Freins",
  dashboard_beneficiaries_filters_received_free_accommodation_carrefour:
    "A bénéficié d'un hébergement gratuit au sein du Carrefour",
  dashboard_beneficiaries_filters_mentor: "A un mentor",
  dashboard_beneficiaries_filters_received_contract_by_contract_window:
    "A bénéficié d'un contrat via le Guichet Premier Contrat",
  dashboard_beneficiaries_filters_handicapped_worker: "Travailleur handicapé",
  dashboard_beneficiaries_filters_reception_modality: "Modalité de réception",
  dashboard_beneficiaries_filters_obastacles_encountred:
    "Type de freins rencontrés",
  dashboard_beneficiaries_filters_project_name: "Nom du projet",
  dashboard_beneficiaries_filters_project_maturity: "Maturité du projet",
  dashboard_beneficiaries_filters_project_activity_sector:
    "Secteur d'activité du projet",
  dashboard_beneficiaries_filters_city: "Ville",
  dashboard_beneficiaries_filters_qpv: "Quartier prioritaire de la ville",
  qpv: "Quartier prioritaire de la ville",
  dashboard_beneficiaries_filters_is_qpv:
    "Fait parti d'un quartier prioritaire de la ville",
  dashboard_beneficiaries_filters_is_youg_capital:
    "Orientation vers un programme d'accompagnement du Capital jeunes créateurs",
  dashboard_beneficiaries_filters_youg_capital_consortium:
    "Capital jeunes créateurs consortium",
  dashboard_beneficiaries_filters_orientations_youg_capital_exact_structure:
    "Capital jeunes créateurs structure exacte ",
  dashboard_beneficiaries_filters_orientations_others:
    "Autres programme d'accompagnement",
  dashboard_beneficiaries_filters_age: "Age",
  dashboard_beneficiaries_filters_rangepicker_before: "Age date de début",
  dashboard_beneficiaries_filters_rangepicker_after: "Age date de fin",
  beneficiaries_table_user_count: "bénéficiaire(s)",
  beneficiaries_add_beneficiarie: "Ajouter un bénéficiaire",
  beneficiaries_export_xlsx: "Export",
  dashboard_table_actions: "Actions",
  beneficiaries_delete_failed: "Erreur durant la supression",
  beneficiaries_delete_success: "Bénéficiaire(s) supprimé(s) avec succès",
  beneficiaries_export_failed: "Erreur durant l'export des bénéficiaires",
  beneficiaries_referent_filters_placeholders:
    "Filtrer les bénéficiaire(s) par référent",
  /* beneficiaries create */

  beneficiaries_create_user_management_title: "Gestion Bénéficiaire",
  beneficiarie_create_form_firstname: "Prénom",
  beneficiarie_create_form_lastname: "Nom de famille",
  beneficiarie_create_form_email: "E-mail",
  beneficiarie_create_form_phone_number: "Numéro de téléphone",
  beneficiarie_create_form_email_format:
    "L'adresse email n'est pas au bon format",
  beneficiarie_create_form_phone_number_format:
    "Le numéro de telephone n'est pas au bon format",
  beneficiarie_create_form_genre: "Genre",
  beneficiarie_create_form_date_of_birth: "Date de naissance",
  beneficiarie_create_form_date_of_birth_placeholder:
    "Sélectionne t'as date de naissance",
  beneficiarie_create_form_genre_placeholder: "Sélectionne ton genre",
  beneficiarie_create_form_level_of_study: "Niveau d'étude",
  beneficiarie_create_form_level_of_study_placeholder:
    "Sélectionne ton niveau d'étude",
  beneficiarie_create_form_labour_market_situation:
    "Situation sur le marché du travail",
  beneficiarie_create_form_labour_market_situation_placeholder:
    "Sélectionne t'as situation sur le marché du travail",
  beneficiarie_create_form_handicapped_worker: "Travailleur handicapé",
  beneficiarie_create_form_handicapped_worker_placeholder:
    "Êtes-vous un travailleur_handicapé",
  beneficiarie_create_form_is_qpv:
    "Résident d'un quartier prioritaire de la ville",
  beneficiarie_create_form_is_qpv_placeholder:
    "Êtes-vous résident d'un quartier prioritaire de la ville",
  beneficiarie_create_form_qpv: "Quartier prioritaire de la ville",
  beneficiarie_create_form_qpv_placeholder:
    "De quel quartier prioritaire de la ville",
  beneficiarie_create_form_reception_modality: "Modalité d'accueil",
  beneficiarie_create_form_reception_modality_placeholder:
    "Sélectionne la modalité d'accueil",
  beneficiarie_create_form_prescriber: "Prescripteur",
  beneficiarie_create_form_prescriber_placeholder:
    "Sélectionne le prescripteur",
  beneficiarie_create_form_obstacles: "Frein",
  beneficiarie_create_form_is_obstacles_placeholder: "Sélectionne un frein",
  beneficiarie_create_form_type_obstacles_encountred:
    "Type de freins rencontrés",
  beneficiarie_create_form_is_type_obstacles_encountred_placeholder:
    "Sélectionne les type de freins rencontrés",
  beneficiarie_create_form_project_name: "Nom du projet",
  beneficiarie_create_form_project_maturity: "Maturité du projet",
  beneficiarie_create_form_is_project_maturity_placeholder:
    "Selectionne la maturité du projet",
  beneficiarie_create_form_project_activity_sector:
    "Secteur d'activité du projet",
  beneficiarie_create_form_is_project_activity_sector_placeholder:
    "Selectionne le secteur d'activité du projet",
  beneficiarie_create_form_project_description: "Description du projet",
  beneficiarie_create_form_commentary: "Commentaire",
  beneficiarie_create_form_referent: "Référent",
  beneficiarie_create_form_referent_placeholder: "Sélectionne un référent",
  beneficiarie_create_failed: "Erreur durant la création du bénéficiaire",
  beneficiarie_create_success: "Bénéficiaire créé avec succès",
  beneficiarie_update_failed: "Erreur durant la modification du bénéficiaire",
  beneficiarie_update_success: "Bénéficiaire modifié avec succès",
  beneficiarie_card_title: "Fiche Bénéficiaire",
  beneficiarie_update_tags_failed: "Erreur durant la modification de(s) tag(s)",
  beneficiarie_update_tags_success: "File(s) Active(s) modifié avec succès",
  beneficiarie_title_created_at: "Créé le",
  beneficiarie_create_received_free_accomodation:
    "A bénéficié d'un hébergement gratuit au sein du Carrefour",
  beneficiarie_create_has_mentor: "A un(e) mentor(e)",
  beneficiarie_create_mentor_identity: "Identité du mentor",
  beneficiarie_create_received_contract_by_contract_window:
    "A bénéficié d'un contrat via le Guichet Premier Contrat",
  beneficiarie_create_user_form_email_not_available:
    "L'adresse email n'est pas disponible",
  beneficiarie_create_postal_code_placeholder: "Code postal",
  beneficiarie_create_postal_code_format:
    "Le code postal n'est pas au bon format",
  /* users */

  users_placeholder_filter: "Saisissez votre recherche",
  users_add_user: "Ajouter un utilisateur",
  users_table_firstame: "Prénom",
  users_table_lastname: "Nom de famille",
  users_table_email: "E-mail",
  users_table_action: "Supprimer",
  users_table_user_count: "utilisateur(s)",
  users_table_actions: "Actions",
  user_delete_failed: "Erreur durant la supression",
  user_delete_success: "Utilisateur(s) supprimé(s) avec succès",
  user_confirm_resend:
    "Êtes-vous sur de vouloir effectuer cette action ? le mot de passe sera changé",
  user_send_success: "L'email a été envoyé avec succés",
  user_send_failed: "Erreur durant l'envoi de l'email",
  user_gender_male: "Homme",
  user_gender_female: "Femme",

  /* user create */

  user_create_user_management_title: "Gestion Utilisateur",
  user_create_user_extra_layout_cancel: "Annuler",
  user_create_user_extra_layout_submit: "Enregistrer",
  user_create_user_form_email_not_available:
    "L'adresse email n'est pas disponible",
  user_create_user_form_email_format: "L'adresse email n'est pas au bon format",
  user_create_form_firstname: "Prénom",
  user_create_form_lastname: "Nom de famille",
  user_create_form_email: "E-mail",
  user_create_form_administrator: "Administrateur",
  user_create_failed: "Erreur durant la création de l'utilisateur",
  user_create_success: "Utilisateur créé avec succès",
  user_create_send_success: "L'email a été envoyé avec succés",
  user_create_send_failed: "Erreur durant l'envoi de l'email",

  /* request */

  request_failure: "Erreur lors de la récupération des données",

  /* reset_password */

  reset_password_complex:
    "Le mot de passe doit contenir 10 caractères, 1 chiffre, 1 minuscule, 1 majuscule, et 1 caractère spécial",
  reset_password_identical: "Les champs mot de passe doivent être identiques",
  reset_password_modal: "Première connexion, veuillez créer votre mot de passe",
  reset_password_save: "Enregistrer",
  reset_password: "Mot de passe",
  reset_password_re: "Répéter votre mot de passe",
  reset_password_success: "Votre mot de passe a été modifié avec succès",
  reset_password_failure:
    "Une erreur est survenue lors de la modification de votre mot de passe",

  /* event */

  event_columns_name: "Nom",
  event_columns_type: "Type",
  event_columns_type_placeholder: "Sélectionne ton type d'événements",
  event_columns_manager: "Responsable",
  event_columns_duration: "Durée",
  event_add: "Ajouter un événement",
  event_create_management_title: "Gestion événements",
  event_fields_description: "Description",
  event_fields_website_link: "Liens du site web",
  event_fields_email_error_format: "L'adresse email n'est pas au bon format",
  event_create_failed: "Erreur durant la création d'un événement",
  event_create_success: "Événements créé avec succès",
  event_table_actions: "Actions",
  event_update_failed: "Erreur durant la modificaton d'un événement",
  event_update_success: "Événement modifié avec succès",
  event_delete_failed: "Erreur durant la supression",
  event_delete_success: "Événement(s) supprimé(s) avec succès",
  event_columns_registered: "Inscrits",
  event_columns_placeholder_registered:
    "Sélectionne le(s) bénéficiaire(s) inscrits a cette événement",
  event_columns_participant: "Participants",
  event_columns_placeholder_participant:
    "Sélectionne le(s) inscrit(s) participant a cette événement",
  event_placeholder_filter: "Saisissez votre recherche",
  events_table_user_count: "Événement(s)",
  events_table_action: "Supprimer",
  events_permanence_provided_CJC_actor:
    "Permanence assurée par un acteur du CJC ?",
  events_export_failed: "Erreur durant l'export des événements",
  events_type_filters_placeholders: "Filtrer les événement par type",

  /* roadmap */

  roadmap_evenement_title: "Événements",
  roadmap_orientations_title: "Orientations vers",
  roadmap_orientations_note: "Note",
  roadmap_add_note: "Ajout d'une note",
  roadmap_link_events: "Liens d'un bénéficiaire a un événement",
  roadmap_add_orientation: "Ajout d'une orientation",
  roadmap_error_select_date_empty: "La date ne peut être vide",
  roadmap_error_note_empty: "La note ne peut être vide",
  roadmap_insert_note_success: "La note a été créée avec succès",
  roadmap_event_placeholder: "Sélectionne un événement",
  roadmap_orientations_consortium_placeholder: "Sélectionne un consortium",
  roadmap_orientations_placeholder: "Sélectionne une orientation",
  roadmap_orientations_structure_exact_placeholder:
    "Sélectionne une structure exacte",
  roadmap_orientations_type_placeholder: "Sélectionne un type d'orientation",
  roadmap_error_events_empty: "L'événement ne peut être vide",
  roadmap_insert_note_failure:
    "Une erreur est survenue lors de la création de note",
  roadmap_insert_event_success:
    "L'event a été relié au bénéficiaire avec succès",
  roadmap_insert_event_failure:
    "Une erreur est survenue lors de la liason de l'événement au bénéficiaire",
  roadmap_error_orientations_empty: "L'orientation ne peut être vide",
  roadmap_no_data:
    "Aucun élément à afficher. Ajoutez une note, un événement ou une orientation avec le bouton +",
  roadmap_insert_orientations_success: "Orientation ajoutée avec succès",
  roadmap_insert_orientations_failure: "L'orientation du bénéficiaire a échoué",
  roadmap_orientations_error_empty: "Le bénéficiaire nécessite une orientation",
  beneficiarie_roadmap_error: "Erreur roadmap",
  roadmap_notes_title: "Titre",
  roadmap_card_title: "Roadmap",

  /* tags */

  tags_table_tag: "File Active",
  tags_table_color: "Couleur",
  tags_table_actions: "Actions",
  tags_delete_failed: "Erreur durant la supression",
  tags_delete_success: "File(s) Active(s) supprimé(s) avec succès",
  tags_table_action: "Supprimer",
  tags_table_user_count: "File(s) Active(s)",
  tags_add_user: "Ajouter une File Active",
  tags_create_tag_management_title: "Gestion File Active",
  tags_columns_tag: "File Active",
  tags_columns_color: "Couleur",
  tags_create_failed: "Erreur durant la création de la File Active",
  tags_create_success: "File Active créée avec succès",
  tags_create_extra_layout_cancel: "Annuler",
  tags_create_extra_layout_submit: "Enregistrer",
  tags_placeholder_filter: "Saisissez votre recherche",
  tags_update_failed: "Erreur durant la modification d'une File Active",
  tags_update_success: "File Active modifiée avec succès",
  tags_card_title: "File Active",

  /* orientations */
  orientations_young_capital_placeholder: "Capital jeunes créateurs",
  orientations_other_placeholder: "Autres",

  exit: "Sortie",
  placeholder_exit: "Sélectionne une sortie",
  funding: "Financement"
};
