import { resettableReducer } from "reduxsauce";
import { CommonTypes } from "./common";
import { AuthReducers, AuthSagas } from "./auth";
import { BeneficiariesReducers, BeneficiariesSagas } from "./beneficiaries";
import { LoaderReducers } from "./loader";
import { combineReducers } from "redux";
import { UsersReducers, UsersSagas } from "./users";
import { EventsReducers, EventsSagas } from "./events";
import { OrientationsReducers, OrientationsSagas } from "./orientations";
import { TagsReducers, TagsSagas } from "./tags";

// Specify reset action type rule
const resettable = resettableReducer(CommonTypes.RESET_REDUCERS);

// Merge our redux together
const reducers = {
  auth: resettable(AuthReducers),
  beneficiaries: resettable(BeneficiariesReducers),
  loader: resettable(LoaderReducers),
  users: resettable(UsersReducers),
  events: resettable(EventsReducers),
  orientations: resettable(OrientationsReducers),
  tags: resettable(TagsReducers)
};

export const rootSaga = [
  ...AuthSagas,
  ...BeneficiariesSagas,
  ...UsersSagas,
  ...EventsSagas,
  ...OrientationsSagas,
  ...TagsSagas
];

export default combineReducers(reducers);
