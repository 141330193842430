import { authenticatedMutation, authenticatedQuery } from "./middleware";
import beneficiariesQueries from "./GraphQLRequest/beneficiariesQueries";
import beneficiariesMutations from "./GraphQLRequest/beneficiariesMutations";
import { methods } from "../utils";
import { authenticatedService } from "./middleware";

const { POST } = methods;
const routes = {
  exportBeneficiaries: process.env.REACT_APP_EXPORT + "/exportBeneficiaries"
};

export default {
  getbeneficiaries: ({ carrefour_id }) =>
    authenticatedQuery(
      beneficiariesQueries.beneficiaries,
      {},
      { carrefour_id }
    ),
  insertBeneficiaries: data =>
    authenticatedMutation(
      beneficiariesMutations.insertBeneficiaries,
      {},
      { data }
    ),
  updateBeneficiaries: ({ id, data }) =>
    authenticatedMutation(
      beneficiariesMutations.updateBeneficiaries,
      {},
      { id, data }
    ),
  deleteBeneficiaries: ids =>
    authenticatedMutation(
      beneficiariesMutations.deleteBeneficiaries,
      {},
      { ids }
    ),
  exportBeneficiaries: ({ beneficiaries, carrefour_id }) =>
    authenticatedService(
      POST,
      routes.exportBeneficiaries,
      { beneficiaries, carrefour_id },
      {},
      {},
      "blob"
    ),
  beneficiariesRoadmap: ({ id }) =>
    authenticatedQuery(beneficiariesQueries.beneficiariesRoadmap, {}, { id }),
  insertBeneficiariesNote: data =>
    authenticatedMutation(
      beneficiariesMutations.insertBeneficiariesNote,
      {},
      { data }
    ),
  insertBeneficiariesTags: data =>
    authenticatedMutation(
      beneficiariesMutations.insertBeneficiariesTags,
      {},
      { data }
    ),
  deleteBeneficiariesTags: ({ ids, id }) =>
    authenticatedMutation(
      beneficiariesMutations.deleteBeneficiariesTags,
      {},
      { ids, id }
    ),
  checkBeneficiariesEmail: email =>
    authenticatedQuery(
      beneficiariesQueries.checkBeneficiariesEmail,
      {},
      { email }
    )
};
