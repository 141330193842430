export const fields = (form, t) => {
  return [
    {
      key: "password",
      title: "reset_password",
      component: "password",
      required: true,
      error: t("reset_password_complex"),
      validator: item => {
        return !item
          ? true
          : item.match(
              /^(?=.*?[A-Z])(?=(.*[a-z])+)(?=(.*\d)+)(?=(.*\W)+)(?!.*\s).{10,}$/g
            );
      }
    },
    {
      key: "re_password",
      title: "reset_password_re",
      component: "password",
      required: true,
      error: t("reset_password_identical"),
      validator: item => {
        const re = form.getFieldValue("password");
        return re === item;
      }
    }
  ];
};
