import { createReducer } from "reduxsauce";
import { types } from "./actions";
import { concat } from "lodash";

const initialState = {
  young_capital: [],
  other: [],
  orientations: [],
  exact_structure: []
};

const getOrientationsSuccess = (state = initialState, action) => {
  const { orientations, exact_structure } = action;

  let young_capital = [];
  let other = [];
  let exact_structure_tmp = [];

  orientations.forEach(i => {
    if (i.young_capital) {
      young_capital.push({
        value: i.id,
        label: i.name,
        young_capital: i.young_capital
      });
    } else {
      other.push({
        value: i.id,
        label: i.name,
        young_capital: i.young_capital
      });
    }
  });

  exact_structure.forEach(i => {
    exact_structure_tmp.push({
      value: i.id,
      label: i.name,
      orientations_id: i.orientations_id
    });
  });

  const tmp = concat(young_capital, other);

  return {
    ...state,
    young_capital: young_capital,
    other: other,
    orientations: tmp,
    exact_structure: exact_structure_tmp
  };
};

export default createReducer(initialState, {
  [types.GET_ORIENTATIONS_SUCCESS]: getOrientationsSuccess
});
