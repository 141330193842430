import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import store from "./store";
import {
  Login,
  Dashboard,
  ForgotPwd,
  Users,
  UserCreate,
  BeneficiarieCreate,
  Event,
  EventCreate,
  Tags,
  TagCreate
} from "../ui/screens";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { history } from "./history";
import { USER_ROLES } from "../utils/mappers";

export const ROUTES = {
  LOGIN: "/",
  FORGOT_PWD: "/forgot-pwd",
  DASHBOARD: "/dashboard",
  USERS: "/users",
  USER_CREATE: "/users/add",
  BENEFICIARIE_CREATE: "/beneficiarie/add",
  BENEFICIARIE_MODIFY: "/beneficiarie/:id",
  EVENT: "/event",
  EVENT_CREATE: "/event/add",
  EVENT_MODIFY: "/event/:id",
  TAGS: "/tags",
  TAG_CREATE: "/tag/add",
  TAG_MODIFY: "/tag/:id"
};

const LoginRoute = ({ children }) => {
  const { token } = store.getState().auth;

  if (token) {
    return <Navigate to={ROUTES.DASHBOARD} replace />;
  }

  return children;
};

const ProtectedRoute = ({ children }) => {
  const { token } = store.getState().auth;

  if (!token) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  return children;
};

const ProtectedUsersRoute = ({ children }) => {
  const { token } = store.getState().auth;
  const { current_user } = store.getState().users;
  const { role } = current_user;

  if (!token) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  if (role === USER_ROLES.REFERENT) {
    return <Navigate to={ROUTES.DASHBOARD} replace />;
  }

  return children;
};

// Main router which handle user navigation
const Router = () => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route
          exact
          path={ROUTES.LOGIN}
          element={<LoginRoute children={<Login />} />}
        />
        <Route
          exact
          path={ROUTES.FORGOT_PWD}
          element={<LoginRoute children={<ForgotPwd />} />}
        />
        <Route
          path={ROUTES.DASHBOARD}
          element={<ProtectedRoute children={<Dashboard />} />}
        />
        <Route
          path={ROUTES.USERS}
          element={<ProtectedUsersRoute children={<Users />} />}
        />
        <Route
          path={ROUTES.USER_CREATE}
          element={<ProtectedUsersRoute children={<UserCreate />} />}
        />
        <Route
          path={ROUTES.BENEFICIARIE_CREATE}
          element={<ProtectedRoute children={<BeneficiarieCreate />} />}
        />
        <Route
          path={ROUTES.BENEFICIARIE_MODIFY}
          element={<ProtectedRoute children={<BeneficiarieCreate />} />}
        />
        <Route
          path={ROUTES.EVENT}
          element={<ProtectedRoute children={<Event />} />}
        />
        <Route
          path={ROUTES.EVENT_CREATE}
          element={<ProtectedRoute children={<EventCreate />} />}
        />
        <Route
          path={ROUTES.EVENT_MODIFY}
          element={<ProtectedRoute children={<EventCreate />} />}
        />
        <Route
          path={ROUTES.TAGS}
          element={<ProtectedRoute children={<Tags />} />}
        />
        <Route
          path={ROUTES.TAG_CREATE}
          element={<ProtectedRoute children={<TagCreate />} />}
        />
        <Route
          path={ROUTES.TAG_MODIFY}
          element={<ProtectedRoute children={<TagCreate />} />}
        />
        <Route component={() => <div>404</div>} />
      </Routes>
    </HistoryRouter>
  );
};

export default Router;
