import {
  authenticatedMutation,
  authenticatedQuery,
  authenticatedService
} from "./middleware";
import usersQueries from "./GraphQLRequest/usersQueries";
import usersMutations from "./GraphQLRequest/usersMutations";
import { methods } from "../utils";

const { POST, GET } = methods;

const routes = {
  email: process.env.REACT_APP_BASE_API_URL + "/user/verify-email",
  signin: process.env.REACT_APP_BASE_API_URL + "/user/signin",
  resend: process.env.REACT_APP_BASE_API_URL + "/user/resend"
};

export default {
  getUsers: ({ carrefour_id, user_id }) =>
    authenticatedQuery(usersQueries.users, {}, { carrefour_id, user_id }),
  getCurrent: ({ email }) =>
    authenticatedQuery(usersQueries.current, {}, { email }),
  createUser: ({ user, carrefour_id }) =>
    authenticatedService(POST, routes.signin, { user, carrefour_id }),
  deleteUser: ids =>
    authenticatedMutation(usersMutations.deleteUser, {}, { ids }),
  resend: id => authenticatedService(POST, routes.resend, { id }),
  emailRequest: email => authenticatedService(GET, routes.email, {}, { email })
};
