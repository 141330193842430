import React, { Fragment } from "react";
import { ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { Router, store } from "./core";
import { GlobalLoader, FirstConnectionModal } from "./ui/components";
import "./assets/style.css";
import "./assets/localize/i18n";

import moment from "moment";
import fr_FR from "antd/lib/locale/fr_FR";
moment.locale("fr");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider locale={fr_FR}>
    <Provider store={store}>
      <Fragment>
        <FirstConnectionModal />
        <Router />
        <GlobalLoader />
      </Fragment>
    </Provider>
  </ConfigProvider>
);

serviceWorker.unregister();
