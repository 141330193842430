import { createAction } from "../../utils";
import events from "../../services/events";

// Types
export const types = {
  GET_TAGS_REQUEST: "GET_TAGS_REQUEST",
  GET_TAGS_SUCCESS: "GET_TAGS_SUCCESS",
  GET_TAGS_FAILURE: "GET_TAGS_FAILURE",

  INSERT_TAGS_REQUEST: "INSERT_TAGS_REQUEST",

  UPDATE_TAGS_REQUEST: "UPDATE_TAGS_REQUEST",

  TAGS_DELETE_REQUEST: "TAGS_DELETE_REQUEST",
  TAGS_DELETE_SUCCESS: "TAGS_DELETE_SUCCESS",

  TAGS_CHANGE_TABLE_PAGESIZE: "TAGS_CHANGE_TABLE_PAGESIZE",
  TAGS_CHANGE_SEARCH_VALUES: "TAGS_CHANGE_SEARCH_VALUES"
};

// Actions
export default {
  getTagsRequest: carrefour_id =>
    createAction(types.GET_TAGS_REQUEST, { carrefour_id }),
  getTagsSuccess: tags => createAction(types.GET_TAGS_SUCCESS, { tags }),
  getTagsFailure: () => createAction(types.GET_TAGS_FAILURE),

  insertTagsRequest: data => createAction(types.INSERT_TAGS_REQUEST, { data }),

  updateTagsRequest: (id, data) =>
    createAction(types.UPDATE_TAGS_REQUEST, { id, data }),

  tagsDeleteRequest: ids => createAction(types.TAGS_DELETE_REQUEST, { ids }),
  tagsDeleteSuccess: ids => createAction(types.TAGS_DELETE_SUCCESS, { ids }),

  changeTablePagesize: (current, pageSize, sorterFilter) =>
    createAction(types.TAGS_CHANGE_TABLE_PAGESIZE, {
      current,
      pageSize,
      sorterFilter
    }),
  changeSearchValues: (result, searchValues) =>
    createAction(types.TAGS_CHANGE_SEARCH_VALUES, { result, searchValues })
};
