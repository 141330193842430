import moment from "moment";

export const capitalizeFirst = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getDuration = (date1, date2) => {
  const startShiftTime = moment(date1);
  const endShiftTime = moment(date2);

  const duration = moment.duration(endShiftTime.diff(startShiftTime));

  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  return `${days} jour(s) ${hours} heure(s) ${minutes} minute(s)`;
};

export const getDifference = (array1, array2) => {
  const tmp1 = array1;
  const tmp2 = array2.map(i => i.value); // initial values
  let toAdd = [];
  let toDelete = [];
  if (tmp1.length > 0 && !tmp1[0].value) {
    toAdd = tmp1.filter(x => !tmp2.includes(x));
    toDelete = tmp2.filter(x => !tmp1.includes(x));
  } else if (tmp1.length === 0) {
    toDelete = tmp2;
  }
  return { add: toAdd, delete: toDelete };
};
