import React, { useEffect, useState } from "react";
import "./style.css";
import { AuthLayout } from "../../layouts/authLayout";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ExtraLayout } from "./extraLayout";
import { useSelector, useDispatch } from "react-redux";
import { UsersActions } from "../../../redux/users";
import { BeneficiarieForm } from "./beneficiarieForm";
import moment from "moment";
import { BeneficiariesActions } from "../../../redux/beneficiaries";
import { assignWith } from "lodash";
import { RoadmapCard } from "./roadmap/roadmapCard";
import { EventsActions } from "../../../redux/events";
import { FileActiveCard } from "./fileActive/fileActiveCard";
import { TagsSelector } from "../../../redux/tags";
import { BeneficiariesService } from "../../../services";
import { getDifference } from "../../../utils/genericFunction";

export const BeneficiarieCreate = () => {
  const [request, setRequest] = useState([]);
  const [fileActive, setFileActive] = useState([]);
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { current_user } = useSelector(state => state.users);
  const {
    beneficiaries,
    roadmap,
    available: emailAvailable,
    loading: emailLoading
  } = useSelector(state => state.beneficiaries);
  const tags = useSelector(TagsSelector.tags);

  const { carrefour_id } = current_user;

  var url = window.location.hash;
  var id = url.substring(url.lastIndexOf("/") + 1);
  let values = beneficiaries.find(b => b.id === parseInt(id)) || null;
  if (values) values.date_of_birth = moment(values.date_of_birth);

  useEffect(() => {
    if (carrefour_id) {
      dispatch(UsersActions.getUsersRequest(carrefour_id));
      dispatch(EventsActions.getEventsRequest(carrefour_id));
    }
  }, [carrefour_id]);

  useEffect(() => {
    if (Number.isInteger(parseInt(id))) {
      dispatch(BeneficiariesActions.getBeneficiariesRoadmapRequest(id));
    } else {
      dispatch(BeneficiariesActions.resetRoadmap());
    }
  }, [id]);

  const prepareTagsRequest = (array, service, type) => {
    const tmp = request;
    array.map(item =>
      type === "insert"
        ? tmp.push({
            service: service,
            data: { tags_id: item, beneficiaries_id: parseInt(id) }
          })
        : tmp.push({
            service: service,
            data: { ids: parseInt(id), id: item }
          })
    );
    setRequest(tmp);
  };

  const onValidate = value => {
    value.date_of_birth = moment(value.date_of_birth).format();
    value.carrefour_id = carrefour_id;
    const nulledObject = assignWith({}, value, (_, v) => typeof v === 'undefined' ? null : v);
    if (!values) {
      prepareTagsRequest(
        fileActive,
        BeneficiariesService.insertBeneficiariesTags,
        "insert"
      );
      dispatch(
        BeneficiariesActions.insertBeneficiariesRequest(
          nulledObject,
          request
        )
      );
    } else {
      const result = getDifference(
        fileActive.map(item => item),
        values.tags_beneficiaries.map(item => ({ value: item.tags_id }))
      );
      console.log("result", result)
      console.log("nulledObject", nulledObject)
      if (result.add.length > 0) {
        prepareTagsRequest(
          result.add,
          BeneficiariesService.insertBeneficiariesTags,
          "insert"
        );
      }
      if (result.delete.length > 0) {
        prepareTagsRequest(
          result.delete,
          BeneficiariesService.deleteBeneficiariesTags,
          "delete"
        );
      }
      dispatch(
        BeneficiariesActions.updateBeneficiariesRequest(
          parseInt(id),
          nulledObject,
          request
        )
      );
    }
  };

  const onFormSubmit = () => {
    if (form) form.submit();
  };

  const onChange = e => {
    setFileActive(e);
  };

  return (
    <AuthLayout
      title={t("beneficiaries_create_user_management_title")}
      current={"1"}
      extra={ExtraLayout(onFormSubmit, navigateTo, t)}
    >
      <BeneficiarieForm
        onValidate={onValidate}
        form={form}
        values={values}
        emailLoading={emailLoading}
        emailAvailable={emailAvailable}
      />
      <FileActiveCard
        tags={tags}
        onChange={onChange}
        values={values}
        setFileActive={setFileActive}
        fileActive={fileActive}
      />
      <RoadmapCard
        roadmap={roadmap}
        beneficiaries_id={parseInt(id)}
        request={request}
        setRequest={setRequest}
      />
    </AuthLayout>
  );
};
