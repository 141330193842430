export default {
  events: `
    query events($carrefour_id: Int!) {
        events(where: {carrefour_id: {_eq: $carrefour_id}}, , order_by: {created_at: desc}) {
          id
          type
          name
          description
          created_at
          start_date
          end_date
          manager
          website_link
          permanence_provided_CJC_actor
          events_beneficiaries {
            id
            events_id
            beneficiaries_id
            participates
          }
          events_beneficiaries_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      `
};
