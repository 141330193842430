import { EditButton, DeleteButton } from "../../components/button";
import { Divider } from "antd";

export const columns = ({onModify, onDelete, sorterFilter, t}) => [
  {
    title: t("event_columns_name"),
    dataIndex: "name",
    align: "center",
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortOrder: sorterFilter?.field === "name" ? sorterFilter?.order : null,
    render: text => <span>{text}</span>
  },
  {
    title: t("event_columns_type"),
    dataIndex: "type",
    align: "center",
    sorter: (a, b) => a.type.localeCompare(b.type),
    sortOrder: sorterFilter?.field === "type" ? sorterFilter?.order : null,
    render: text => <span>{text}</span>
  },
  {
    title: t("event_columns_manager"),
    dataIndex: "manager",
    align: "center",
    sorter: (a, b) => a.manager.localeCompare(b.manager),
    sortOrder: sorterFilter?.field === "manager" ? sorterFilter?.order : null,
    render: text => <span>{text}</span>
  },
  {
    title: t("event_columns_duration"),
    dataIndex: "duration",
    align: "center",
    render: text => <span>{text}</span>
  },
  {
    title: t("event_columns_registered"),
    dataIndex: "registered",
    sorter: (a, b) => a.registered - b.registered,
    sortOrder:
      sorterFilter?.field === "registered" ? sorterFilter?.order : null,
    align: "center",
    render: text => <span>{text}</span>
  },
  {
    title: t("event_columns_participant"),
    dataIndex: "participates",
    sorter: (a, b) => a.participates - b.participates,
    sortOrder:
      sorterFilter?.field === "participates" ? sorterFilter?.order : null,
    align: "center",
    render: text => <span>{text}</span>
  },
  {
    title: t("users_table_actions"),
    key: "action",
    width: 100,
    render: item => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <EditButton
            title={t("user_confirm_resend")}
            modify={() => onModify(item.id)}
          />
          <Divider type="vertical" />
          <DeleteButton deletion={() => onDelete([item && item.id])} />
        </div>
      );
    }
  }
];
