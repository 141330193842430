import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Button, Col, Layout, Popconfirm, Row, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { AuthActions } from "../../../redux/auth";
import { SideMenu } from "../../components/sideMenu";

const { Header, Content } = Layout;

export const AuthLayout = ({ children, current, title, extra }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onLogout = () => {
    dispatch(AuthActions.logout());
  };

  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SideMenu current={current} inlineCollapsed={collapsed}>
      <Header className={"header-head"}>
        <Row className={"header-container"}>
          <Col span={6} className={"header-start-container"}>
            {collapsed ? (
              <MenuUnfoldOutlined
                onClick={toggleCollapsed}
                style={{ marginLeft: 15 }}
              />
            ) : (
              <MenuFoldOutlined
                onClick={toggleCollapsed}
                style={{ marginLeft: 15 }}
              />
            )}
          </Col>
          <Col span={12} className="header-container">
            <img
              src={require("../../../assets/images/logo.png")}
              className={"header-logo-image"}
            />
          </Col>
          <Col span={6} type="flex" className={"header-end-container"}>
            <Popconfirm
              title={t("auth_layout_confirm_logout")}
              okText="Oui"
              cancelText="Non"
              placement="bottomRight"
              onConfirm={onLogout}
            >
              <Button
                id={"logout"}
                icon={<UserOutlined />}
                style={{
                  marginRight: 18,
                  color: "#17ac8b",
                  borderColor: "#17ac8b",
                  backgroundColor: "#F6F7F8"
                }}
                shape="circle"
              />
            </Popconfirm>
          </Col>
        </Row>
      </Header>
      <PageHeader title={title} extra={extra} />
      <Content
        id={"content"}
        style={{
          height: dimensions.height - 80,
          overflow: "auto",
          marginLeft: 10,
          marginRight: 10
        }}
      >
        <div className={"header-contant-children"}>{children}</div>
      </Content>
    </SideMenu>
  );
};
