import React from "react";

import { Select, Tag } from "antd";

const { Option } = Select;

const TagRender = ({ item, tags }) => {
  const { label, value, closable, onClose } = item;
  const onPreventMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      key={value}
      color={tags.find(item => item.value === value).color}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3
      }}
    >
      {label}
    </Tag>
  );
};

export const SelectTags = ({ tags, onChange, value }) => {
  return (
    <Select
      mode="multiple"
      showArrow
      allowClear
      value={value}
      tagRender={item => <TagRender item={item} tags={tags} />}
      style={{
        width: "100%"
      }}
      onChange={e => onChange(e)}
    >
      {tags.map((item, index) => (
        <Option value={item.value} key={`${item.value}-${index}`}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};
