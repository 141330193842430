import { default as EventsActions, types } from "./actions";
import { call, put, all, takeLatest, select } from "redux-saga/effects";
import { BeneficiariesService, EventsService } from "../../services";
import { LoaderActions } from "../loader";
import i18next from "i18next";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { get } from "lodash";
import moment from "moment";
import { ROUTES } from "../../core/router";
import { history } from "../../core/history";
import download from "downloadjs";

const formatEvent = (events, carrefour_id) => {
  return {
    name: events.name,
    manager: events.manager,
    type: events.type,
    start_date: moment(events.start_date[0]._d).format(),
    end_date: moment(events.start_date[1]._d).format(),
    description: events.description,
    website_link: events.website_link,
    permanence_provided_CJC_actor: events.permanence_provided_CJC_actor,
    carrefour_id: carrefour_id
  };
};

function* getEventsRequest({ carrefour_id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(EventsService.getEvents, {
    carrefour_id
  });
  if (response) {
    yield put(EventsActions.getEventsSuccess(get(response, "events", [])));
    yield put(LoaderActions.loaded());
    return;
  }
  showError("request_failure", i18next.t);
  yield put(LoaderActions.loaded());
}

function* createEvents({ events, carrefour_id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(
    EventsService.insertEvents,
    formatEvent(events, carrefour_id)
  );
  if (response) {
    yield all(
      get(events, "registered", []).map(id =>
        call(EventsService.insertBeneficiariesEvents, {
          beneficiaries_id: id,
          events_id: get(response, ["insert_events_one", "id"], 0),
          participates: get(events, "participant", []).includes(id)
        })
      )
    );
    showSuccess("event_create_success", i18next.t);
    yield call(history.push, ROUTES.EVENT);
    yield put(LoaderActions.loaded());
    return;
  }
  showError("event_create_failed", i18next.t);
  yield put(LoaderActions.loaded());
}

function* updateEvents({ events_id, events, carrefour_id }) {
  yield put(LoaderActions.loading());
  let calls = [];
  let results = [];
  const [error, response] = yield call(EventsService.updateEvents, {
    id: events_id,
    data: formatEvent(events, carrefour_id)
  });
  if (response) {
    if (events.registered.delete.length > 0) {
      calls.push({
        service: EventsService.deleteBeneficiariesEvents,
        data: {
          ids: events.registered.delete,
          id: events_id
        }
      });
    }
    if (events.registered.add.length > 0)
      events.registered.add.map(id =>
        calls.push({
          service: EventsService.insertBeneficiariesEvents,
          data: {
            beneficiaries_id: id,
            events_id: events_id,
            participates: events.participant.add.includes(id)
          }
        })
      );
    if (
      events.participant.add.length > 0 ||
      events.participant.delete.length > 0
    )
      calls.push({
        service: EventsService.updateBeneficiariesEvents,
        data: {
          ids:
            events.participant.add.length > 0
              ? events.participant.add
              : events.participant.delete,
          id: events_id,
          bool: events.participant.add.length > 0 ? true : false
        }
      });
    if (calls.length > 0) {
      results = yield all(
        calls.map(({ service, data }) => call(service, data))
      );
    }
    const hasError = results.some(res => res[0]);
    if (!hasError) {
      showSuccess("event_update_success", i18next.t);
      yield call(history.push, ROUTES.EVENT);
      yield put(LoaderActions.loaded());
      return;
    }
  }
  showError("event_update_failed", i18next.t);
  yield call(history.push, ROUTES.EVENT);
  yield put(LoaderActions.loaded());
}

function* eventsDelete({ ids }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(EventsService.deleteEvents, ids);
  if (response) {
    yield put(
      EventsActions.eventsDeleteSuccess(
        get(response, ["delete_events", "returning"], [])
      )
    );
    showSuccess("event_delete_success", i18next.t);
    yield call(history.push, ROUTES.EVENT);
    yield put(LoaderActions.loaded());
    return;
  }
  showError("event_delete_failed", i18next.t);
  yield put(LoaderActions.loaded());
}

function* exportEvents({ valueList, carrefour_id }) {
  yield put(LoaderActions.loading());
  const { current_user } = yield select(state => state.users);
  const prefix = get(current_user, ["carrefour", "name"], "").match(
    /.{1,3}/g
  )[0];

  const tmp = valueList.map(item => {
    return { ...item, id: prefix + item.id };
  });

  const [error, response] = yield call(EventsService.exportEvents, {
    events: tmp,
    carrefour_id: carrefour_id
  });

  if (response) {
    download(
      response.data,
      `export-events-${moment().format("DD_MM_YYYY")}.xlsx`,
      {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }
    );
  } else {
    showError("events_export_failed", i18next.t);
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_EVENTS_REQUEST, getEventsRequest),
  takeLatest(types.CREATE_EVENTS_REQUEST, createEvents),
  takeLatest(types.UPDATE_EVENTS_REQUEST, updateEvents),
  takeLatest(types.EVENTS_DELETE_REQUEST, eventsDelete),
  takeLatest(types.EXPORT_EVENTS, exportEvents)
];
