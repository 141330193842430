import { createSelector } from "reselect";
import { get, compact } from "lodash";

const STORE_NAME = "beneficiaries";

const beneficiariesList = createSelector(
  state => get(state, [STORE_NAME, "beneficiaries"], {}),
  beneficiaries =>
    beneficiaries.map(item => ({
      value: item.id,
      label: `${item.firstname} ${item.lastname}`
    }))
);

export default {
  beneficiariesList
};
