import { createAction } from "../../utils";

// Types
export const types = {
  GET_BENEFICIARIES_REQUEST: "GET_BENEFICIARIES_REQUEST",
  GET_BENEFICIARIES_SUCCESS: "GET_BENEFICIARIES_SUCCESS",
  GET_BENEFICIARIES_FAILURE: "GET_BENEFICIARIES_FAILURE",

  INSERT_BENEFICIARIES_REQUEST: "INSERT_BENEFICIARIES_REQUEST",

  UPDATE_BENEFICIARIES_REQUEST: "UPDATE_BENEFICIARIES_REQUEST",

  BENEFICIARIES_DELETE_REQUEST: "BENEFICIARIES_DELETE_REQUEST",
  BENEFICIARIES_DELETE_SUCCESS: "BENEFICIARIES_DELETE_SUCCESS",

  EXPORT_BENEFICIARIES: "EXPORT_BENEFICIARIES",

  GET_BENEFICIARIES_ROADMAP_REQUEST: "GET_BENEFICIARIES_ROADMAP_REQUEST",
  GET_BENEFICIARIES_ROADMAP_SUCCESS: "GET_BENEFICIARIES_ROADMAP_SUCCESS",

  INSERT_BENEFICIARIES_NOTE_REQUEST: "INSERT_BENEFICIARIES_NOTE_REQUEST",

  INSERT_BENEFICIARIES_EVENTS_REQUEST: "INSERT_BENEFICIARIES_EVENTS_REQUEST",

  INSERT_BENEFICIARIES_ORIENTATIONS_REQUEST:
    "INSERT_BENEFICIARIES_ORIENTATIONS_REQUEST",

  INSERT_ROADMAP_SUCCESS: "INSERT_ROADMAP_SUCCESS",

  RESET_ROADMAP: "RESET_ROADMAP",

  BENEFICIARIES_CHANGE_TABLE_PAGESIZE: "BENEFICIARIES_CHANGE_TABLE_PAGESIZE",
  BENEFICIARIES_CHANGE_BENEFICIARIES_FILTERS:
    "BENEFICIARIES_CHANGE_BENEFICIARIES_FILTERS",
  BENEFICIARIES_CHANGE_SEARCH_VALUES: "BENEFICIARIES_CHANGE_SEARCH_VALUES",

  CHECK_BENEFICIARIES_EMAIL_REQUEST: "CHECK_BENEFICIARIES_EMAIL_REQUEST",
  CHECK_BENEFICIARIES_EMAIL_SUCCESS: "CHECK_BENEFICIARIES_EMAIL_SUCCESS",
  BENEFICIARIES_EMAIL_LOADING: "BENEFICIARIES_EMAIL_LOADING",

  RESET_FILTERS: "RESET_FILTERS"
};

// Actions
export default {
  getBeneficiariesRequest: carrefour_id =>
    createAction(types.GET_BENEFICIARIES_REQUEST, {
      carrefour_id
    }),
  getBeneficiariesSuccess: beneficiaries =>
    createAction(types.GET_BENEFICIARIES_SUCCESS, { beneficiaries }),
  getBeneficiariesFailure: () => createAction(types.GET_BENEFICIARIES_FAILURE),

  insertBeneficiariesRequest: (data, request) =>
    createAction(types.INSERT_BENEFICIARIES_REQUEST, { data, request }),

  updateBeneficiariesRequest: (id, data, request) =>
    createAction(types.UPDATE_BENEFICIARIES_REQUEST, { id, data, request }),

  beneficiariesDeleteRequest: ids =>
    createAction(types.BENEFICIARIES_DELETE_REQUEST, { ids }),
  beneficiariesDeleteSuccess: ids =>
    createAction(types.BENEFICIARIES_DELETE_SUCCESS, { ids }),

  exportBeneficiaries: (valueList, carrefour_id) =>
    createAction(types.EXPORT_BENEFICIARIES, { valueList, carrefour_id }),

  getBeneficiariesRoadmapRequest: id =>
    createAction(types.GET_BENEFICIARIES_ROADMAP_REQUEST, {
      id
    }),
  getBeneficiariesRoadmapSuccess: (roadmap, orientations) =>
    createAction(types.GET_BENEFICIARIES_ROADMAP_SUCCESS, {
      roadmap,
      orientations
    }),

  insertBeneficiariesNoteRequest: data =>
    createAction(types.INSERT_BENEFICIARIES_NOTE_REQUEST, { data }),

  insertBeneficiariesEventsRequest: ({ beneficiaries_id, events_id }) =>
    createAction(types.INSERT_BENEFICIARIES_EVENTS_REQUEST, {
      beneficiaries_id,
      events_id
    }),
  insertBeneficiariesOrientationsRequest: data =>
    createAction(types.INSERT_BENEFICIARIES_ORIENTATIONS_REQUEST, {
      data
    }),
  insertRoadmapSuccess: ({ array, roadmap_type }) =>
    createAction(types.INSERT_ROADMAP_SUCCESS, {
      array,
      roadmap_type
    }),

  resetRoadmap: () => createAction(types.RESET_ROADMAP),

  changeTablePagesize: (current, pageSize, record, sorterFilter) =>
    createAction(types.BENEFICIARIES_CHANGE_TABLE_PAGESIZE, {
      current,
      pageSize,
      record,
      sorterFilter
    }),

  changeBeneficiariesFilters: filters =>
    createAction(types.BENEFICIARIES_CHANGE_BENEFICIARIES_FILTERS, { filters }),

  changeSearchValues: (result, searchValues) =>
    createAction(types.BENEFICIARIES_CHANGE_SEARCH_VALUES, {
      result,
      searchValues
    }),

  checkBeneficiariesEmailRequest: email =>
    createAction(types.CHECK_BENEFICIARIES_EMAIL_REQUEST, {
      email
    }),

  checkBeneficiariesEmailSuccess: available =>
    createAction(types.CHECK_BENEFICIARIES_EMAIL_SUCCESS, {
      available
    }),
  beneficiariesEmailLoading: loading =>
    createAction(types.BENEFICIARIES_EMAIL_LOADING, {
      loading
    }),
  resetFilters: () => createAction(types.RESET_FILTERS)
};
