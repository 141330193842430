import React from "react";
import { Input, Checkbox, DatePicker } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { SelectForm } from "../../components";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

export const components = t => {
  return {
    textarea: ({ key, title }) => (
      <TextArea
        id={key}
        showCount
        maxLength={5000}
        placeholder={`${t(title)}`}
      />
    ),
    input: ({ key, title, args }) => (
      <Input
        id={key}
        placeholder={`${t(title)}`}
        maxLength={50}
        suffix={args ? <LoadingOutlined /> : <span />}
      />
    ),
    select: ({ args, placeholder, key, mode }) => (
      <SelectForm id={key} array={args} placeholder={placeholder} mode={mode} />
    ),
    datepicker: ({ key, placeholder }) => (
      <RangePicker
        showTime
        id={key}
        locale={locale}
        placeholder={placeholder}
        style={{ width: "100%" }}
        format={"DD/MM/YYYY HH:mm"}
      />
    ),
    checkbox: ({ key, title }) => <Checkbox id={key}>{t(title)}</Checkbox>
  };
};
