import React from "react";
import { Input, Checkbox, DatePicker } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { SelectForm } from "../../components";
import moment from "moment";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";

const { TextArea } = Input;

export const components = t => {
  return {
    textarea: ({ key, title }) => (
      <TextArea
        id={key}
        placeholder={`${t(title)}`}
        showCount
        maxLength={5000}
      />
    ),
    input: ({ key, title, args }) => (
      <Input
        id={key}
        placeholder={`${t(title)}`}
        maxLength={50}
        suffix={args ? <LoadingOutlined /> : <span />}
      />
    ),
    select: ({ args, placeholder, key }) => (
      <SelectForm id={key} array={args} placeholder={placeholder} />
    ),
    datepicker: ({ key, placeholder }) => (
      <DatePicker
        id={key}
        locale={locale}
        defaultPickerValue={moment().subtract(16, "years")}
        disabledDate={d =>
          !d ||
          d.isAfter(moment().subtract(16, "years")) ||
          d.isSameOrBefore(moment().subtract(99, "years"))
        }
        placeholder={placeholder}
        style={{ width: "100%" }}
        format={"DD/MM/YYYY"}
      />
    ),
    checkbox: ({ key, title }) => <Checkbox id={key}>{t(title)}</Checkbox>
  };
};
