import React from "react";
import { DropDownFilter } from "../../../components";
import { useTranslation } from "react-i18next";
import "../style.css";
import { useSelector } from "react-redux";

export const OrientationsFilters = ({
  filters,
  beneficiaries,
  handleFilterClick
}) => {
  const { t } = useTranslation();

  const { young_capital, other, exact_structure } = useSelector(
    state => state.orientations
  );
  return (
    <div className={"filters_container"}>
      <div className={"filters_group_container"}>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.is_young_capital}
            array={[true, false]}
            filterKey={"is_young_capital"}
            handleFilterClick={handleFilterClick}
            placeholder={t("dashboard_beneficiaries_filters_is_youg_capital")}
            isBool={true}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.consortium}
            array={young_capital.map(i => i.label)}
            filterKey={"consortium"}
            handleFilterClick={handleFilterClick}
            placeholder={t(
              "dashboard_beneficiaries_filters_youg_capital_consortium"
            )}
            width={450}
          />
        </div>
      </div>
      <div className={"filters_group_container"}>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.other_filter}
            array={other.map(i => i.label)}
            filterKey={"other_filter"}
            handleFilterClick={handleFilterClick}
            placeholder={t(
              "dashboard_beneficiaries_filters_orientations_others"
            )}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.exact_structure_filters}
            array={exact_structure.map(i => i.label)}
            filterKey={"exact_structure_filters"}
            handleFilterClick={handleFilterClick}
            placeholder={t(
              "dashboard_beneficiaries_filters_orientations_youg_capital_exact_structure"
            )}
            width={450}
          />
        </div>
      </div>
    </div>
  );
};
