import { createSelector } from "reselect";
import { get } from "lodash";

const STORE_NAME = "tags";

const tags = createSelector(
  state => get(state, [STORE_NAME, "tags"], {}),
  tags =>
    tags.map(item => ({
      value: item.id,
      label: item.tag,
      color: item.color
    }))
);

export default {
  tags
};
