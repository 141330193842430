import { createAction } from "../../utils";
import events from "../../services/events";

// Types
export const types = {
  GET_EVENTS_REQUEST: "GET_EVENTS_REQUEST",
  GET_EVENTS_SUCCESS: "GET_EVENTS_SUCCESS",
  GET_EVENTS_FAILURE: "GET_EVENTS_FAILURE",

  CREATE_EVENTS_REQUEST: "CREATE_EVENTS_REQUEST",

  UPDATE_EVENTS_REQUEST: "UPDATE_EVENTS_REQUEST",

  EVENTS_DELETE_REQUEST: "EVENTS_DELETE_REQUEST",
  EVENTS_DELETE_SUCCESS: "EVENTS_DELETE_SUCCESS",

  EVENTS_CHANGE_TABLE_PAGESIZE: "EVENTS_CHANGE_TABLE_PAGESIZE",
  EVENTS_CHANGE_SEARCH_VALUES: "EVENTS_CHANGE_SEARCH_VALUES",

  EXPORT_EVENTS: "EXPORT_EVENTS",

  EVENTS_CHANGE_EVENTS_FILTERS: "EVENTS_CHANGE_EVENTS_FILTERS"
};

// Actions
export default {
  getEventsRequest: carrefour_id =>
    createAction(types.GET_EVENTS_REQUEST, {
      carrefour_id
    }),
  getEventsSuccess: events =>
    createAction(types.GET_EVENTS_SUCCESS, { events }),
  getEventsFailure: () => createAction(types.GET_EVENTS_FAILURE),

  createEventsRequest: (events, carrefour_id) =>
    createAction(types.CREATE_EVENTS_REQUEST, { events, carrefour_id }),

  updateEventsRequest: (events_id, events, carrefour_id) =>
    createAction(types.UPDATE_EVENTS_REQUEST, {
      events_id,
      events,
      carrefour_id
    }),

  eventsDeleteRequest: ids =>
    createAction(types.EVENTS_DELETE_REQUEST, { ids }),
  eventsDeleteSuccess: ids =>
    createAction(types.EVENTS_DELETE_SUCCESS, { ids }),

  changeTablePagesize: (current, pageSize, sorterFilter) =>
    createAction(types.EVENTS_CHANGE_TABLE_PAGESIZE, {
      current,
      pageSize,
      sorterFilter
    }),
  changeSearchValues: (result, searchValues) =>
    createAction(types.EVENTS_CHANGE_SEARCH_VALUES, { result, searchValues }),

  exportEvents: (valueList, carrefour_id) =>
    createAction(types.EXPORT_EVENTS, { valueList, carrefour_id }),

  changeEventsFilters: filters =>
    createAction(types.EVENTS_CHANGE_EVENTS_FILTERS, { filters })
};
