import { createAction } from "../../utils";

// Types
export const types = {
  GET_CURRENT_USER_REQUEST: "GET_CURRENT_USER_REQUEST",
  GET_CURRENT_USER_SUCCESS: "GET_CURRENT_USER_SUCCESS",
  GET_CURRENT_USER_FAILURE: "GET_CURRENT_USER_FAILURE",

  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",

  USERS_CREATE_REQUEST: "USERS_CREATE_REQUEST",

  EMAIL_REQUEST: "EMAIL_REQUEST",
  EMAIL_SUCCESS: "EMAIL_SUCCESS",
  EMAIL_LOADING: "EMAIL_LOADING",

  USERS_DELETE_REQUEST: "USERS_DELETE_REQUEST",
  USERS_DELETE_SUCCESS: "USERS_DELETE_SUCCESS",

  RESEND_REQUEST: "RESEND_REQUEST",

  USERS_CHANGE_TABLE_PAGESIZE: "USERS_CHANGE_TABLE_PAGESIZE",
  USERS_CHANGE_SEARCH_VALUES: "USERS_CHANGE_SEARCH_VALUES"
};

// Actions
export default {
  getCurrentUserRequest: username =>
    createAction(types.GET_CURRENT_USER_REQUEST, {
      username
    }),
  getCurrentUserSuccess: current_user =>
    createAction(types.GET_CURRENT_USER_SUCCESS, { current_user }),
  getCurrentUserFailure: () => createAction(types.GET_CURRENT_USER_FAILURE),

  getUsersRequest: carrefour_id =>
    createAction(types.GET_USERS_REQUEST, {
      carrefour_id
    }),
  getUsersSuccess: users => createAction(types.GET_USERS_SUCCESS, { users }),
  getUsersFailure: () => createAction(types.GET_USERS_FAILURE),

  usersCreateRequest: (user, carrefour_id) =>
    createAction(types.USERS_CREATE_REQUEST, { user, carrefour_id }),

  emailRequest: email => createAction(types.EMAIL_REQUEST, { email }),
  emailSuccess: available => createAction(types.EMAIL_SUCCESS, { available }),
  emailLoading: loading => createAction(types.EMAIL_LOADING, { loading }),

  resendRequest: id => createAction(types.RESEND_REQUEST, { id }),

  usersDeleteRequest: ids => createAction(types.USERS_DELETE_REQUEST, { ids }),
  usersDeleteSuccess: ids => createAction(types.USERS_DELETE_SUCCESS, { ids }),

  changeTablePagesize: (current, pageSize, sorterFilter) =>
    createAction(types.USERS_CHANGE_TABLE_PAGESIZE, {
      current,
      pageSize,
      sorterFilter
    }),
  changeSearchValues: (result, searchValues) =>
    createAction(types.USERS_CHANGE_SEARCH_VALUES, { result, searchValues })
};
