import { encode } from "base-64";
import { methods } from "../utils";
import { service, authenticatedService } from "./middleware";

const { POST, GET } = methods;
const routes = {
  login: process.env.REACT_APP_BASE_API_URL + "/auth/authorize",
  getToken: process.env.REACT_APP_BASE_API_URL + "/auth/token",
  register: process.env.REACT_APP_BASE_API_URL + "/auth/register",
  forgotPwd: process.env.REACT_APP_BASE_API_URL + "/user/forgot_pwd_mail",
  changePassword: process.env.REACT_APP_BASE_API_URL + "/user/change_password"
};

export default {
  login: ({ username, password }) =>
    service(
      GET,
      routes.login,
      {},
      {
        prompt: "none",
        response_type: "code",
        redirect_uri: process.env.REACT_APP_BASE_API_URL,
        client_id: "carburateur_webapp"
      },
      {
        Authorization: "Basic " + encode(username + ":" + password)
      }
    ),
  register: data => service(POST, routes.register, data, {}, {}),
  getToken: ({ username, password, code }) =>
    service(
      POST,
      routes.getToken,
      "grant_type=authorization_code&code=" + code,
      {},
      {
        Authorization: "Basic " + encode(username + ":" + password)
      }
    ),
  refreshToken: ({ authorization, refresh_token }) =>
    service(
      POST,
      routes.getToken,
      "grant_type=refresh_token&refresh_token=" + refresh_token,
      {},
      {
        Authorization: "Basic " + authorization
      }
    ),
  forgotPwd: data => service(POST, routes.forgotPwd, data, {}),
  changePassword: ({ user_id, password }) =>
    authenticatedService(POST, routes.changePassword, {
      user_id,
      password
    })
};
