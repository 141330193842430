import { EditButton, DeleteButton } from "../../components/button";
import { Divider } from "antd";
import { get } from "lodash";

export const columns = ({onModify, onDelete, record, sorterFilter, t}) => [
  {
    title: t("dashboard_beneficiaries_firstame"),
    dataIndex: "firstname",
    align: "center",
    sorter: (a, b) => a.firstname.localeCompare(b.firstname),
    sortOrder: sorterFilter?.field === "firstname" ? sorterFilter?.order : null,
    render: text => <span>{text}</span>
  },
  {
    title: t("dashboard_beneficiaries_lastname"),
    dataIndex: "lastname",
    align: "center",
    sorter: (a, b) => a.lastname.localeCompare(b.lastname),
    sortOrder: sorterFilter?.field === "lastname" ? sorterFilter?.order : null,
    render: text => <span>{text}</span>
  },
  {
    title: t("dashboard_beneficiaries_genre"),
    dataIndex: "genre",
    filters: [
      {
        text: t("dashboard_beneficiaries_man"),
        value: 'male'
      },
      {
        text: t("dashboard_beneficiaries_woman"),
        value: 'female'
      }
    ],
    align: "center",
    onFilter: (value, record) => {
      return get(record, "genre", "male").indexOf(value) === 0
    },
    filteredValue: record?.genre,
    render: text => <span>{t(`user_gender_${text}`)}</span>
  },
  {
    title: t("dashboard_beneficiaries_email"),
    dataIndex: "email",
    align: "center",
    sorter: (a, b) => a.email.localeCompare(b.email),
    sortOrder: sorterFilter?.field === "email" ? sorterFilter?.order : null,
    render: text => <span>{text}</span>
  },
  {
    title: t("dashboard_beneficiaries_phone_number"),
    dataIndex: "phone_number",
    align: "center",
    render: text => <span>{text}</span>
  },
  {
    title: t("dashboard_beneficiaries_age"),
    dataIndex: "age",
    align: "center",
    sorter: (a, b) => a.age - b.age,
    sortOrder: sorterFilter?.field === "age" ? sorterFilter?.order : null,
    render: text => <span>{text}</span>
  },
  {
    title: t("users_table_actions"),
    key: "action",
    width: 100,
    render: item => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <EditButton
            title={t("user_confirm_resend")}
            modify={() => onModify(item.id)}
          />
          <Divider type="vertical" />
          <DeleteButton deletion={() => onDelete([item && item.id])} />
        </div>
      );
    }
  }
];
