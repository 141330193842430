import React, { useEffect, useState } from "react";
import { Card, Form, Input } from "antd";
import { components } from "./formComponents";
import { fields } from "./formFields";
import { useTranslation } from "react-i18next";
import { UsersActions } from "../../../redux/users";
import { useDispatch } from "react-redux";
import { ColorInput } from "../../components/colorInput";

export const TagForm = ({ onValidate, values, form }) => {
  const [color, setColor] = useState(values !== undefined ? values.color : "");
  const { t } = useTranslation();

  useEffect(() => {
    if (values) {
      form.resetFields();
    }
  }, [values]);

  const onFinish = values => {
    if (onValidate) onValidate(values);
  };

  const requiredRules = ({ required, ...item }) => {
    let ret = {
      message: t("required_field"),
      required
    };
    if (!item.component) ret = { ...ret, whitespace: true };
    return ret;
  };

  const onFinishFailed = ({ errorFields }) => {
    const id = errorFields[0]?.name[0];
    if (id) {
      const el = document.getElementById(`${id}-form`);
      el.scrollIntoView();
    }
  };

  const handleOnChangeFormValue = color => {
    setColor(color.hex);
    form.setFieldsValue({ color: color.hex });
  };
  return (
    <Card style={{ margin: 25, width: "80%" }}>
      <Form
        initialValues={values}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        {fields.map(
          (
            {
              key,
              title,
              validateTrigger,
              valuePropName,
              noStyle,
              error,
              validator,
              args,
              component
            },
            index
          ) => (
            <div id={`${key}-form`}>
              <Form.Item
                validateTrigger={validateTrigger || "onChange"}
                noStyle={noStyle}
                name={key}
                label={component !== "checkbox" && t(title)}
                valuePropName={valuePropName || "value"}
                rules={[
                  requiredRules(fields[index]),
                  () => ({
                    validator(rule, value) {
                      if (validator && !validator(value))
                        return Promise.reject(error || `${t(key)} incorrect`);
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                {components(t)[component || "input"]({
                  key,
                  title,
                  args,
                  color
                })}
              </Form.Item>
            </div>
          )
        )}
      </Form>
      <ColorInput
        color={color}
        handleOnChangeFormValue={handleOnChangeFormValue}
      />
    </Card>
  );
};
