import { get } from "lodash";
/**
 * Check if the given error is a GraphQLError. return the first graphQLError if it is, null otherwise
 * @param error the error to check
 */
export const isGraphQLError = error => {
  let err = error;
  if (Array.isArray(error)) {
    err = error[0];
  } else if (
    get(error, "graphQLErrors", null) &&
    Array.isArray(get(error, "graphQLErrors", null))
  ) {
    err = get(error, ["graphQLErrors", 0], "");
  }

  console.log("isGraphQLError: ", err);
  if (get(err, ["extensions", "code"], "") !== "") {
    return err;
  } else {
    return null;
  }
};

/**
 * retreive the code for a graphQLError
 * @param error the GraphQL code
 */
export const extractGraphQLCode = error => {
  let err = isGraphQLError(error);
  if (err == null) return "";

  const code = get(err, ["extensions", "code"], "");
  return code;
};
