import { createSelector } from "reselect";
import { get, compact } from "lodash";
import moment from "moment";
import { TYPES } from "../../ui/screens/beneficiarieCreate/roadmap/RenderMappers";

const STORE_NAME = "events";

const events = createSelector(
  state => get(state, [STORE_NAME, "events"], {}),
  events =>
    events.map(item => ({
      id: item.id,
      name: item.name,
      type: item.type,
      manager: item.manager,
      start_date: moment(item.start_date).format("DD/MM/YYYY, HH:mm:ss"),
      end_date: moment(item.end_date).format("DD/MM/YYYY, HH:mm:ss"),
      created_at: moment(item.created_at).format("DD_MM_YYYY HH:mm"),
      duration: item.duration,
      registered: get(
        item,
        ["events_beneficiaries_aggregate", "aggregate", "count"],
        0
      ),
      participates: compact(
        get(item, ["events_beneficiaries"], []).map(i => {
          if (i.participates) return 1;
        })
      ).length,
      permanence_provided_CJC_actor: item.permanence_provided_CJC_actor
    }))
);

const events_create = (id, beneficiaries) =>
  createSelector(
    state => get(state, [STORE_NAME, "events"], {}),
    events => {
      if (Number.isInteger(id)) {
        const values = events.find(e => e.id === parseInt(id));
        values.start_date = [
          moment(values.start_date),
          moment(values.end_date)
        ];
        values.registered = beneficiaries.filter(i =>
          values.events_beneficiaries
            .map(i => i.beneficiaries_id)
            .includes(i.value)
        );
        values.participant = beneficiaries.filter(i =>
          values.events_beneficiaries
            .map(i => {
              if (i.participates) return i.beneficiaries_id;
            })
            .includes(i.value)
        );
        return values;
      }
      return null;
    }
  );

const eventsList = createSelector(
  state => ({
    events: get(state, [STORE_NAME, "events"], {}),
    roadmap: get(state, ["beneficiaries", "roadmap"], {})
  }),
  ({ events, roadmap }) =>
    compact(
      events.map(item => {
        if (
          !roadmap
            .filter(r => r.type === TYPES.EVENTS)
            .some(i => i.id === item.id)
        ) {
          return {
            value: item.id,
            label: item.name
          };
        } else return null;
      })
    )
);

export default {
  events,
  events_create,
  eventsList
};
