import React from "react";
import { Input, Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export const components = t => {
  return {
    password: ({ key, title }) => (
      <Input id={key} placeholder={t(title)} type="password" />
    )
  };
};
