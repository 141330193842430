import React, { useState, useRef } from "react";
import { Card, Form, Divider } from "antd";
import { VerticalLine } from "../../../components";
import "../style.css";
import {
  RenderItem,
  RenderIcon,
  RenderColor,
  RenderTitle,
  RenderChildren,
  RenderNoData,
  TYPES
} from "./RenderMappers";
import { MultipleChoice } from "./multipleChoice";
import { VerticallyCenteredModal } from "../../../components/modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BeneficiariesActions } from "../../../../redux/beneficiaries";
import { EventsSelector } from "../../../../redux/events";
import {
  BeneficiariesService,
  EventsService,
  OrientationsService
} from "../../../../services";
import moment from "moment";

export const RoadmapCard = ({
  roadmap,
  beneficiaries_id,
  request,
  setRequest
}) => {
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [form] = Form.useForm();

  const raodmapRef = useRef(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const eventsList = useSelector(EventsSelector.eventsList);
  const { events } = useSelector(state => state.events);
  const { young_capital, other, orientations, exact_structure } = useSelector(
    state => state.orientations
  );

  const wrapper = document.querySelector(".wrapper");

  let array = roadmap;

  const onFormSubmit = () => {
    if (form) form.submit();
  };

  const resetForm = () => {
    setOpenModal({ open: false, type: null });
    form.resetFields();
    wrapper.classList.toggle("wrapper-open");
  };

  const onValidate = values => {
    resetForm();
    values.beneficiaries_id = beneficiaries_id;
    if (values.beneficiaries_id) {
      onValidateUpdate(values);
    } else {
      onValidateInsert(values);
    }
  };

  const onValidateUpdate = values => {
    switch (openModal.type) {
      case TYPES.NOTES:
        dispatch(BeneficiariesActions.insertBeneficiariesNoteRequest(values));
        break;
      case TYPES.EVENTS:
        values.events_id = values.events;
        delete values.events;
        dispatch(BeneficiariesActions.insertBeneficiariesEventsRequest(values));
        break;
      case TYPES.ORIENTATIONS:
        values.orientations_id =
          values.orientations_type !== "other"
            ? values.young_capital
            : values.other;
        delete values.young_capital;
        delete values.other;
        delete values.orientations_type;
        dispatch(
          BeneficiariesActions.insertBeneficiariesOrientationsRequest(values)
        );
        break;
    }
  };

  const onValidateInsert = values => {
    let tmp = request;
    switch (openModal.type) {
      case TYPES.NOTES:
        tmp.push({
          service: BeneficiariesService.insertBeneficiariesNote,
          data: values,
          type: TYPES.NOTES
        });
        break;
      case TYPES.EVENTS:
        values.events_id = values.events;
        values.participates = false;
        delete values.events;
        tmp.push({
          service: EventsService.insertBeneficiariesEvents,
          data: values,
          type: TYPES.EVENTS
        });
        break;
      case TYPES.ORIENTATIONS:
        values.orientations_id =
          values.orientations_type !== "other"
            ? values.young_capital
            : values.other;
        delete values.young_capital;
        delete values.other;
        delete values.orientations_type;
        tmp.push({
          service: OrientationsService.insertOrientations,
          data: values,
          type: TYPES.ORIENTATIONS
        });
        break;
    }
    feedRoadmap(values, openModal.type);
    setRequest(tmp);
  };

  const feedRoadmap = (values, type) => {
    let tmp = { ...values };
    if (type === TYPES.EVENTS) {
      tmp = events.find(event => event.id === tmp.events_id);
      tmp.date = tmp.start_date;
    }
    if (type === TYPES.ORIENTATIONS) {
      tmp.to = orientations.find(
        item => item.value === tmp.orientations_id
      ).label;
    }
    tmp.type = type;
    tmp.date = tmp.date ? tmp.date : moment(new Date());

    array.push(tmp);
    array.sort(function(a, b) {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
  };

  return (
    <Card
      className="roadmap-card-container"
      style={{ margin: 25, width: "90%" }}
      ref={raodmapRef}
    >
      <Divider orientation="left">{t("roadmap_card_title")}</Divider>
      {roadmap.length === 0 ? (
        <RenderNoData t={t} />
      ) : (
        <VerticalLine
          array={array}
          RenderColor={RenderColor}
          RenderIcon={RenderIcon}
          RenderItem={RenderItem}
        />
      )}
      <MultipleChoice
        isOpen={openModal.open}
        setIsOpen={setOpenModal}
        wrapper={wrapper}
      />
      <VerticallyCenteredModal
        getTitle={() => RenderTitle(openModal.type, t)}
        isOpen={openModal.open}
        setIsOpen={setOpenModal}
        onValidate={onFormSubmit}
        RenderChildren={() =>
          RenderChildren(
            openModal.type,
            form,
            onValidate,
            eventsList,
            young_capital,
            other,
            exact_structure,
            t
          )
        }
      />
    </Card>
  );
};
