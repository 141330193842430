import React from "react";
import { Button } from "antd";
import { ROUTES } from "../../../core/router";

export const ExtraLayout = (form, navigateTo, t) => {
  return (
    <div className="extra-layout-div">
      <Button
        onClick={() => navigateTo(ROUTES.TAGS)}
        style={{ marginRight: 30 }}
        danger
        className="login-form-button"
      >
        {t("tags_create_extra_layout_cancel")}
      </Button>
      <Button
        htmlType="submit"
        type="primary"
        onClick={() => form.submit()}
        className="login-form-button"
      >
        {t("tags_create_extra_layout_submit")}
      </Button>
    </div>
  );
};
