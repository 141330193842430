export default {
  insertEvent: `
    mutation insertEvent($data: events_insert_input!) {
        insert_events_one(object: $data) {
          id
        }
      }
      `,
  updateEvents: `
    mutation updateEvents($id: Int!, $data: events_set_input) {
      update_events_by_pk(pk_columns: {id: $id}, _set: $data) {
        id
      }
    }
    `,
  deleteEvents: `
    mutation deleteEvents($ids: [Int!]!) {
      delete_events(where: { id: { _in: $ids } }) {
        returning {
          id
        }
      }
    }`,
  insertBeneficiariesEvents: `
    mutation insertBeneficiariesEvents($data: events_beneficiaries_insert_input!) {
      insert_events_beneficiaries_one(object: $data) {
        id
        event {
          id
          name
          description
          start_date
          end_date
        }
      }
    }
    `,
  deleteBeneficiariesEvents: `
    mutation deleteBeneficiariesEvents($ids: [Int!]!, $id: Int!) {
      delete_events_beneficiaries(where: { beneficiaries_id: { _in: $ids }, _and: {events_id: {_eq: $id}}}) {
        returning {
          id
        }
      }
    }`,
  updateBeneficiariesEvents: `
    mutation updateBeneficiariesEvents($ids: [Int!]!, $id: Int!, $bool: Boolean!) {
      update_events_beneficiaries(where: {beneficiaries_id: {_in: $ids}, _and: {events_id: {_eq: $id}}}, _set: {participates: $bool}) {
        returning {
          id
        }
      }
    }
    `
};
