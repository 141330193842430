import { createAction } from "../../utils";
import events from "../../services/events";

// Types
export const types = {
  GET_ORIENTATIONS_REQUEST: "GET_ORIENTATIONS_REQUEST",
  GET_ORIENTATIONS_SUCCESS: "GET_ORIENTATIONS_SUCCESS",
  GET_ORIENTATIONS_FAILURE: "GET_ORIENTATIONS_FAILURE"
};

// Actions
export default {
  getOrientationsRequest: () => createAction(types.GET_ORIENTATIONS_REQUEST),
  getOrientationsSuccess: (orientations, exact_structure) =>
    createAction(types.GET_ORIENTATIONS_SUCCESS, {
      orientations,
      exact_structure
    }),
  getOrientationsFailure: () => createAction(types.GET_ORIENTATIONS_FAILURE)
};
