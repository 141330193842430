import { default as OrientationsActions, types } from "./actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { OrientationsService } from "../../services";
import { LoaderActions } from "../loader";
import i18next from "i18next";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { get } from "lodash";

function* getOrientationsRequest() {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(OrientationsService.getOrientations);
  if (response) {
    yield put(
      OrientationsActions.getOrientationsSuccess(
        get(response, "orientations", []),
        get(response, "exact_structure", [])
      )
    );
    yield put(LoaderActions.loaded());
    return;
  }
  showError("request_failure", i18next.t);
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_ORIENTATIONS_REQUEST, getOrientationsRequest)
];
