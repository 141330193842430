import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import Fuse from "fuse.js";

const { Search } = Input;

export const Filter = ({
  list,
  onFilter,
  disabled,
  keys,
  placeHolder,
  searchValues
}) => {
  const options = {
    shouldSort: true,
    tokenize: true,
    includeMatches: true,
    threshold: 0.3,
    location: 0,
    distance: 20000,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: keys
  };

  const [value, setValue] = useState(searchValues);

  useEffect(() => {
    if (searchValues === null) {
      setValue(null);
    }
  }, [searchValues]);

  useEffect(() => {
    if (list && value) onSearch(value);
  }, [list]);

  const newList = (list || []).map((l, i) => {
    let ret = { ...l, i };
    keys.forEach(item => {
      if (typeof ret[item] === "string") {
        ret[item] = ret[item]
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      }
    });
    return ret;
  });

  let fuse = new Fuse(newList, options);

  const onSearch = value => {
    const newValue = value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (!value || value === "") {
      onFilter(null);
      return;
    }
    setValue(value);
    let result = fuse.search(newValue);
    onFilter(
      result.map(({ item }) => list[item.i]),
      value
    );
    return value;
  };

  const handleSearchChange = e => {
    const { value } = e.target;
    if (value.length === 0) onSearch(value);
    setValue(value);
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Search
        id={"search"}
        style={{ width: 300 }}
        placeholder={placeHolder}
        value={value}
        onChange={handleSearchChange}
        allowClear={true}
        onSearch={onSearch}
        enterButton
        disabled={disabled}
      />
    </div>
  );
};
