import React, { useEffect, useState } from "react";
import { Card, Form, Divider } from "antd";
import { components } from "./formComponents";
import { fields } from "./formFields";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { UsersSelector } from "../../../redux/users";
import moment from "moment";
import { BeneficiariesActions } from "../../../redux/beneficiaries";

export const BeneficiarieForm = ({
  onValidate,
  values,
  form,
  emailAvailable,
  emailLoading
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const referent = useSelector(UsersSelector.referent);
  const [available, setAvailable] = useState(true);

  useEffect(() => {
    if (values) {
      form.resetFields();
    }
  }, [values]);

  useEffect(() => {
    if (emailAvailable !== available) form.validateFields(["email"]);
    setAvailable(emailAvailable);
  }, [emailAvailable]);

  const onFinish = values => {
    if (onValidate) onValidate(values);
  };

  const requiredRules = ({ required, ...item }) => {
    let ret = {
      message: t("required_field"),
      required
    };
    if (required && !item.component) ret = { ...ret, whitespace: true };
    return ret;
  };

  const onFinishFailed = ({ errorFields }) => {
    const id = get(errorFields, [0, "name", 0], 0);
    if (id) {
      const el = document.getElementById(`${id}-form`);
      el.scrollIntoView();
    }
  };

  const checkEmailAvailable = email => {
    if (get(values, "email", "") !== form.getFieldValue("email")) {
      dispatch(BeneficiariesActions.checkBeneficiariesEmailRequest(email));
    }
  };

  return (
    <Card style={{ margin: 25, width: "90%" }}>
      <p style={{ textAlign: "right" }}>
        {values !== null
          ? `${t("beneficiarie_title_created_at")} ${moment(
              values.entry_date
            ).format("DD/MM/YYYY HH:mm")}`
          : ""}
      </p>
      <Divider orientation="left">{t("beneficiarie_card_title")}</Divider>
      <Form
        initialValues={values}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        {fields(checkEmailAvailable, emailLoading, referent, t).map(
          (
            {
              key,
              title,
              placeholder,
              validateTrigger,
              valuePropName,
              noStyle,
              error,
              validator,
              errorMail,
              args,
              component
            },
            index
          ) => (
            <div id={`${key}-form`}>
              <Form.Item
                validateTrigger={validateTrigger || "onChange"}
                noStyle={noStyle}
                name={key}
                label={component !== "checkbox" && t(title)}
                valuePropName={valuePropName || "value"}
                rules={[
                  requiredRules(
                    fields(checkEmailAvailable, emailLoading, referent, t)[
                      index
                    ]
                  ),
                  () => ({
                    validator(rule, value) {
                      const values = form.getFieldsValue()
                      if (validator && !validator(value, values))
                        return Promise.reject(error || `${t(key)} incorrect`);
                      if (errorMail && value && !emailAvailable)
                        return Promise.reject(errorMail);
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                {components(t)[component || "input"]({
                  key,
                  title,
                  placeholder,
                  args
                })}
              </Form.Item>
            </div>
          )
        )}
      </Form>
    </Card>
  );
};
