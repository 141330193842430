export default {
  users: `
      query users($carrefour_id: Int!) {
        users(where: {carrefour_id: {_eq: $carrefour_id}}) {
            email
            firstname
            lastname
            id
            role
          }
      }`,
  current: `
    query current($email: String!) {
        users(where: {email: {_eq: $email}}) {
            email
            firstname
            lastname
            carrefour_id
            id
            role
            renew_pwd
            carrefour {
              name
            }
        }
      }
    `
};
