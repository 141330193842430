import { createReducer } from "reduxsauce";

import { types } from "./actions";

const initialState = {
  tags: [],
  pageSize: 10,
  current: 1,
  searchValues: null,
  searchValuesResult: null,
  sorterFilter: null
};

const getTagsSuccess = (state = initialState, action) => {
  const { tags } = action;

  return { ...state, tags: tags };
};

const changeTablePagesize = (state = initialState, action) => {
  const { current, pageSize, sorterFilter } = action;

  return {
    ...state,
    current: current,
    pageSize: pageSize,
    sorterFilter: sorterFilter
  };
};

const changeSearchValues = (state = initialState, action) => {
  const { result, searchValues } = action;

  return { ...state, searchValuesResult: result, searchValues: searchValues };
};

const tagsDeleteSuccess = (state = initialState, action) => {
  const { tags } = state;
  const { ids } = action;
  const tags_ids = ids.map(item => item.id);
  const tmp = tags?.filter(item => !tags_ids.includes(item.id));
  return { ...state, tags: tmp };
};

export default createReducer(initialState, {
  [types.GET_TAGS_SUCCESS]: getTagsSuccess,
  [types.TAGS_DELETE_SUCCESS]: tagsDeleteSuccess,
  [types.TAGS_CHANGE_TABLE_PAGESIZE]: changeTablePagesize,
  [types.TAGS_CHANGE_SEARCH_VALUES]: changeSearchValues
});
