import React from "react";
import { DeleteButton } from "../../components";
import { Typography } from "antd";
import "./style.css";

const { Text } = Typography;

export const actionsRender = (handleReset, onDelete, selectedRowKeys, t) => {
  return (
    <>
      <span>
        <Text strong>{`${selectedRowKeys.length} ${t(
          "beneficiaries_table_user_count"
        )}`}</Text>
      </span>
      <br />
      <br />
    </>
  );
};
