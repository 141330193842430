import { Select } from "antd";
import React from "react";

const { Option } = Select;

export const SelectForm = ({ array, value, onChange, placeholder, mode }) => {
  const handleOnSearch = (input, option) => {
    const newValue = input
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    return option.children.toLowerCase().includes(newValue);
  };

  return (
    <Select
      mode={mode}
      allowClear
      value={value}
      getPopupContainer={trigger => trigger.parentNode}
      onChange={onChange}
      placeholder={placeholder}
      showSearch
      filterOption={handleOnSearch}
    >
      {array.map((item, index) => (
        <Option
          value={item.value !== undefined ? item.value : item}
          key={`${item}-${index}`}
        >
          {item.label || item}
        </Option>
      ))}
    </Select>
  );
};
