import React, { useEffect, useState } from "react";
import { Card, Form } from "antd";
import { components } from "./formComponents";
import { fields } from "./formFields";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BeneficiariesSelector } from "../../../redux/beneficiaries";

export const EventForm = ({ onValidate, values, form }) => {
  const beneficiaries = useSelector(BeneficiariesSelector.beneficiariesList);
  const [registered, setRegistered] = useState(
    values
      ? beneficiaries.filter(i =>
          values.registered.map(i => i.value).includes(i.value)
        )
      : []
  );
  const [type, setType] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (values) {
      form.resetFields();
      setType(form.getFieldValue().type);
    }
  }, [values]);

  const onFinish = values => {
    if (onValidate) onValidate(values);
  };

  const requiredRules = ({ required, ...item }) => {
    let ret = {
      message: t("required_field"),
      required
    };
    if (!item.component) ret = { ...ret, whitespace: true };
    return ret;
  };

  const onFinishFailed = ({ errorFields }) => {
    const id = errorFields[0]?.name[0];
    if (id) {
      const el = document.getElementById(`${id}-form`);
      el.scrollIntoView();
    }
  };

  const onRegisteredChange = item => {
    if (item.type) {
      setType(item.type);
    }
    if (item.registered) {
      setRegistered(
        beneficiaries.filter(i => item.registered.includes(i.value))
      );
    }
  };

  return (
    <Card style={{ margin: 25, width: "80%" }}>
      <Form
        initialValues={values}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        onValuesChange={onRegisteredChange}
        layout="vertical"
        form={form}
      >
        {fields(beneficiaries, registered, t).map(
          (
            {
              key,
              title,
              placeholder,
              validateTrigger,
              valuePropName,
              noStyle,
              error,
              validator,
              mode,
              errorMail,
              args,
              component,
              condition_type
            },
            index
          ) => (
            <div id={`${key}-form`}>
              <Form.Item
                validateTrigger={validateTrigger || "onChange"}
                noStyle={noStyle}
                name={key}
                hidden={
                  key === "permanence_provided_CJC_actor" &&
                  type !== condition_type
                }
                label={component !== "checkbox" && t(title)}
                valuePropName={valuePropName || "value"}
                rules={[
                  requiredRules(fields(beneficiaries, registered, t)[index]),
                  () => ({
                    validator(rule, value) {
                      if (validator && !validator(value))
                        return Promise.reject(error || `${t(key)} incorrect`);
                      if (errorMail && value && value !== values?.email)
                        return Promise.reject(errorMail);
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                {components(t)[component || "input"]({
                  key,
                  title,
                  placeholder,
                  args,
                  mode
                })}
              </Form.Item>
            </div>
          )
        )}
      </Form>
    </Card>
  );
};
