import React from "react";
import { Button, Form, Image, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AuthActions } from "../../../../redux/auth";
import { ROUTES } from "../../../../core/router";
import "./style.css";
import logo from "../../../../assets/images/logo.png";

export const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onFinish = ({ username, password }) => {
    dispatch(AuthActions.loginRequest(username, password));
  };

  return (
    <section id="allForm">
      <Image className={"login-logo"} alt="logo" preview={false} src={logo} />
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: t("login_empty_username") }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t("login_username")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t("login_empty_password") }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t("login_password")}
          />
        </Form.Item>
        <Form.Item>
          <a className="login-form-forgot" href={`/#${ROUTES.FORGOT_PWD}`}>
            <p className="login-form-forgot-text">
              {t("login_forgot_password")}
            </p>
          </a>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            <p>{t("login_submit")}</p>
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};
