import React from "react";
import { DeleteButton } from "../../components";
import { Typography } from "antd";
import "./style.css";

const { Text } = Typography;

export const actionsRender = (handleReset, onDelete, selectedRowKeys, t) => {
  return (
    <>
      <span>
        <span className="action-render-delete-button-container">
          <DeleteButton
            title={t("users_table_action")}
            onCancel={() => handleReset()}
            deletion={() => onDelete(selectedRowKeys)}
          />
        </span>
        <Text strong>{`${selectedRowKeys.length} ${t(
          "users_table_user_count"
        )}`}</Text>
      </span>
      <br />
      <br />
    </>
  );
};
