import { DeleteOutlined } from "@ant-design/icons";
import React from "react";
import { MailOutlined } from "@ant-design/icons";
import ConfirmButton from "./ConfirmButton";
import { useTranslation } from "react-i18next";
import { Popconfirm, Button } from "antd";

const MailButton = ({ title, resend }) => {
  const { t } = useTranslation();
  return (
    <Popconfirm
      title={title}
      okText={t("general_yes")}
      cancelText={t("general_no")}
      placement="right"
      onConfirm={resend}
    >
      <Button id={"logout"} icon={<MailOutlined />} shape="circle" />
    </Popconfirm>
  );
};

export default MailButton;
