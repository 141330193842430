import { createReducer } from "reduxsauce";

import { types } from "./actions";

const initialState = {
  users: [],
  current_user: {},
  available: true,
  loading: false,
  pageSize: 10,
  current: 1,
  searchValues: null,
  searchValuesResult: null,
  sorterFilter: null
};

const getUsers = (state = initialState, action) => {
  const { users } = action;

  return { ...state, users: users };
};

const getCurrentUser = (state = initialState, action) => {
  const { current_user } = action;

  return { ...state, current_user: current_user };
};

const emailSuccess = (state = initialState, action) => {
  const { available } = action;

  return { ...state, available };
};

const emailLoading = (state = initialState, action) => {
  const { loading } = action;

  return { ...state, loading };
};

const userDeleteSuccess = (state = initialState, action) => {
  const { users } = state;
  const { ids } = action;
  const user_ids = ids.map(item => item.id);
  const tmp = users?.filter(item => !user_ids.includes(item.id));
  return { ...state, users: tmp };
};

const changeTablePagesize = (state = initialState, action) => {
  const { current, pageSize, sorterFilter } = action;

  return {
    ...state,
    current: current,
    pageSize: pageSize,
    sorterFilter: sorterFilter
  };
};

const changeSearchValues = (state = initialState, action) => {
  const { result, searchValues } = action;

  return { ...state, searchValuesResult: result, searchValues: searchValues };
};

export default createReducer(initialState, {
  [types.GET_USERS_SUCCESS]: getUsers,
  [types.GET_CURRENT_USER_SUCCESS]: getCurrentUser,
  [types.EMAIL_SUCCESS]: emailSuccess,
  [types.EMAIL_LOADING]: emailLoading,
  [types.USERS_DELETE_SUCCESS]: userDeleteSuccess,
  [types.USERS_CHANGE_TABLE_PAGESIZE]: changeTablePagesize,
  [types.USERS_CHANGE_SEARCH_VALUES]: changeSearchValues
});
