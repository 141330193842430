import { EditOutlined } from "@ant-design/icons";
import React from "react";

import ConfirmButton from "./ConfirmButton";

export default ({ loading, modify, onCancel }) => {
  return (
    <ConfirmButton
      loading={loading}
      type={"dashed"}
      shape={"circle"}
      icon={<EditOutlined />}
      onClick={modify}
      onCancel={onCancel}
    />
  );
};
