import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import french from "./strings_fr";

i18next.use(initReactI18next).init({
  debug: true,
  fallbackLng: "fr",
  resources: {
    fr: {
      translation: french
    }
  }
});
