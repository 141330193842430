import React, { useEffect } from "react";
import Icon, {
  UserOutlined,
  HomeOutlined,
  CalendarOutlined,
  TagOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./sideMenu.css";
import { ROUTES } from "../../../core/router";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../../../utils/mappers";

const { Sider } = Layout;
const { SubMenu } = Menu;

export const SideMenu = ({ inlineCollapsed, children, current }) => {
  const navigateTo = useNavigate();
  const { t } = useTranslation();

  const { current_user } = useSelector(state => state.users);

  const { role } = current_user;

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const routes = [
    {
      key: "1",
      title: t("side_menu_beneficiaries"),
      path: ROUTES.DASHBOARD,
      icon: HomeOutlined,
      disabled: false
    },
    {
      key: "2",
      title: t("side_menu_event"),
      path: ROUTES.EVENT,
      icon: CalendarOutlined
    },
    {
      key: "3",
      title: t("side_menu_users"),
      path: ROUTES.USERS,
      icon: UserOutlined,
      disabled: role === USER_ROLES.ADMIN ? false : true
    },
    {
      key: "4",
      title: t("side_menu_tags"),
      path: ROUTES.TAGS,
      icon: TagOutlined
    }
  ];

  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onClick = ({ key }) => {
    const route = routes.find(r =>
      r.sub ? r.sub.find(s => s.key === key) : r.key === key
    );

    if (route.sub) {
      let subRoute = route.sub.find(s => s.key === key);
      navigateTo(subRoute.path);
    } else {
      navigateTo(route.path);
    }
  };

  return (
    <div id={"partners"} className={"screen-wrapper"}>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider collapsed={inlineCollapsed}>
          <Menu
            theme="dark"
            defaultSelectedKeys={[current]}
            mode="inline"
            onClick={onClick}
          >
            {routes.map(r =>
              !r.disabled ? (
                r.sub ? (
                  <SubMenu
                    key={r.key}
                    title={
                      <span>
                        <Icon component={r.icon} />
                        <span>{r.title}</span>
                      </span>
                    }
                  >
                    {r.sub.map(s => (
                      <Menu.Item key={s.key}>
                        <Icon component={s.icon} />
                        <span>{s.title}</span>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={r.key}>
                    <Icon component={r.icon} />
                    <span>{r.title}</span>
                  </Menu.Item>
                )
              ) : null
            )}
          </Menu>
        </Sider>
        <Layout style={{ height: dimensions.height }}>{children}</Layout>
      </Layout>
    </div>
  );
};
