import { createReducer } from "reduxsauce";
import moment from "moment";

import { types } from "./actions";
import { getDuration } from "../../utils/genericFunction";

const initialState = {
  events: [],
  pageSize: 10,
  current: 1,
  filters: {},
  searchValues: null,
  searchValuesResult: null,
  record: null,
  sorterFilter: null
};

const getEvents = (state = initialState, action) => {
  const { events } = action;

  events.map(i => {
    i.duration = getDuration(i.start_date, i.end_date);
  });

  return { ...state, events: events };
};

const eventsDeleteSuccess = (state = initialState, action) => {
  const { events } = state;
  const { ids } = action;
  const events_ids = ids.map(item => item.id);
  const tmp = events?.filter(item => !events_ids.includes(item.id));
  return { ...state, events: tmp };
};

const changeTablePagesize = (state = initialState, action) => {
  const { current, pageSize, sorterFilter } = action;

  return {
    ...state,
    current: current,
    pageSize: pageSize,
    sorterFilter: sorterFilter
  };
};

const changeEventsFilters = (state = initialState, action) => {
  const { filters } = action;

  return { ...state, filters: filters };
};
const changeSearchValues = (state = initialState, action) => {
  const { result, searchValues } = action;

  return { ...state, searchValuesResult: result, searchValues: searchValues };
};

export default createReducer(initialState, {
  [types.GET_EVENTS_SUCCESS]: getEvents,
  [types.EVENTS_DELETE_SUCCESS]: eventsDeleteSuccess,
  [types.EVENTS_CHANGE_TABLE_PAGESIZE]: changeTablePagesize,
  [types.EVENTS_CHANGE_SEARCH_VALUES]: changeSearchValues,
  [types.EVENTS_CHANGE_EVENTS_FILTERS]: changeEventsFilters
});
