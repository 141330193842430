import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  FormOutlined,
  CalendarOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";
import { Form, Input, DatePicker } from "antd";
import "../style.css";
import locale from "antd/es/date-picker/locale/fr_FR";
import { SelectForm } from "../../../components";
import { useState } from "react";
import { t } from "react-i18next";
import { useSelector } from "react-redux";
const { TextArea } = Input;

export const TYPES = {
  NOTES: "notes",
  EVENTS: "events",
  ORIENTATIONS: "orientations"
};

export const ORIENTATIONS_TYPES = {
  YOUNG_CAPITAL: "young_capital",
  OTHER: "other"
};
export const RenderItem = ({ item, type }) => {
  const { t } = useTranslation();

  const { exact_structure } = useSelector(state => state.orientations);

  switch (type) {
    case TYPES.NOTES:
      return (
        <>
          <h3 className="vertical-timeline-element-title">
            {`${t("roadmap_orientations_note")} ${
              item.title !== null && item.title !== undefined ? item.title : ""
            }`}
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            {moment(item.date).format("DD/MM/YYYY HH:mm")}
          </h4>
          <p>{item.note}</p>
        </>
      );
    case TYPES.EVENTS:
      return (
        <>
          <h3 className="vertical-timeline-element-title">{`${t(
            "roadmap_evenement_title"
          )} ${item.name}`}</h3>
          <h4 className="vertical-timeline-element-subtitle">
            {`${moment(item.start_date).format("DD/MM/YYYY HH:mm")} => ${moment(
              item.end_date
            ).format("DD/MM/YYYY HH:mm")}`}
          </h4>
          <p>{item.description}</p>
        </>
      );
    case TYPES.ORIENTATIONS:
      return (
        <>
          <h3 className="vertical-timeline-element-title">{`${t(
            "roadmap_orientations_title"
          )} ${item.to} ${
            item.exact_structure_id && item.exact_structure_id !== undefined
              ? " - " +
                exact_structure.find(e => e.value === item.exact_structure_id)
                  .label
              : ""
          }`}</h3>
          <h4 className="vertical-timeline-element-subtitle">
            {moment(item.date).format("DD/MM/YYYY HH:mm")}
          </h4>
        </>
      );
  }
};

export const RenderIcon = type => {
  switch (type) {
    case TYPES.NOTES:
      return <FormOutlined />;
    case TYPES.EVENTS:
      return <CalendarOutlined />;
    case TYPES.ORIENTATIONS:
      return <UserSwitchOutlined />;
  }
};

export const RenderColor = type => {
  switch (type) {
    case TYPES.NOTES:
      return { background: "#82c072", color: "#fff" };
    case TYPES.EVENTS:
      return { background: "#006b96", color: "#fff" };
    case TYPES.ORIENTATIONS:
      return { background: "#ff7043", color: "#fff" };
  }
};

export const RenderTitle = (type, t) => {
  switch (type) {
    case TYPES.NOTES:
      return t("roadmap_add_note");
    case TYPES.EVENTS:
      return t("roadmap_link_events");
    case TYPES.ORIENTATIONS:
      return t("roadmap_add_orientation");
  }
};

export const RenderNoData = ({ t }) => (
  <div className="roadmap-no-data">
    <h3 className="vertical-timeline-element-title">{t("roadmap_no_data")}</h3>
  </div>
);

const RenderFormNotes = ({ form, onFinish, t }) => (
  <Form
    name="roadmap_notes"
    className="roadmap-notes-form"
    initialValues={{ remember: true }}
    onFinish={onFinish}
    form={form}
  >
    <Form.Item
      name="date"
      rules={[
        { required: true, message: t("roadmap_error_select_date_empty") }
      ]}
    >
      <DatePicker
        locale={locale}
        style={{ width: "100%" }}
        format={"DD/MM/YY"}
      />
    </Form.Item>
    <Form.Item name="title">
      <Input placeholder={t("roadmap_notes_title")} maxLength={50} />
    </Form.Item>
    <Form.Item
      name="note"
      rules={[{ required: true, message: t("roadmap_error_note_empty") }]}
    >
      <TextArea rows={4} showCount maxLength={5000} />
    </Form.Item>
  </Form>
);

const RenderFormEvents = ({ form, onFinish, eventsList, t }) => (
  <Form
    name="roadmap_events"
    className="roadmap-events-form"
    onFinish={onFinish}
    form={form}
  >
    <Form.Item
      name="events"
      rules={[{ required: true, message: t("roadmap_error_events_empty") }]}
    >
      <SelectForm
        array={eventsList}
        placeholder={t("roadmap_event_placeholder")}
      />
    </Form.Item>
  </Form>
);

const RenderFormOrientations = ({
  form,
  onFinish,
  young_capital,
  other,
  exact_structure,
  t
}) => {
  const [orientations, setOrientations] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [consortium, setConsortium] = useState(null);

  const onChange = item => {
    setOrientations(item);
    setTrigger(true);
  };

  const onOrientationChange = item => {
    setConsortium(item);
    form.resetFields(["exact_structure_id"]);
  };
  return (
    <Form
      name="roadmap_orientations"
      className="roadmap-orientations-form"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        name="orientations_type"
        rules={[
          { required: true, message: t("roadmap_error_orientations_empty") }
        ]}
      >
        <SelectForm
          array={[
            {
              value: ORIENTATIONS_TYPES.YOUNG_CAPITAL,
              label: t("orientations_young_capital_placeholder")
            },
            { value: "other", label: t("orientations_other_placeholder") }
          ]}
          onChange={e => onChange(e)}
          placeholder={t("roadmap_orientations_type_placeholder")}
        />
      </Form.Item>
      {trigger ? (
        <>
          <Form.Item
            name={orientations}
            rules={[
              {
                required: true,
                message: t("roadmap_error_orientations_empty")
              }
            ]}
          >
            <SelectForm
              array={
                orientations === ORIENTATIONS_TYPES.YOUNG_CAPITAL
                  ? young_capital
                  : other
              }
              onChange={e => onOrientationChange(e)}
              placeholder={
                orientations === ORIENTATIONS_TYPES.YOUNG_CAPITAL
                  ? t("roadmap_orientations_consortium_placeholder")
                  : t("roadmap_orientations_placeholder")
              }
            />
          </Form.Item>
          {exact_structure.some(e => e.orientations_id === consortium) &&
          orientations === ORIENTATIONS_TYPES.YOUNG_CAPITAL ? (
            <Form.Item
              name={"exact_structure_id"}
              rules={[
                {
                  required: true,
                  message: t("roadmap_error_orientations_empty")
                }
              ]}
            >
              <SelectForm
                array={exact_structure.filter(
                  e => e.orientations_id === consortium
                )}
                placeholder={t(
                  "roadmap_orientations_structure_exact_placeholder"
                )}
              />
            </Form.Item>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};

export const RenderChildren = (
  type,
  form,
  onFinish,
  eventsList,
  young_capital,
  other,
  exact_structure,
  t
) => {
  switch (type) {
    case TYPES.NOTES:
      return <RenderFormNotes form={form} onFinish={onFinish} t={t} />;
    case TYPES.EVENTS:
      return (
        <RenderFormEvents
          form={form}
          onFinish={onFinish}
          eventsList={eventsList}
          t={t}
        />
      );
    case TYPES.ORIENTATIONS:
      return (
        <RenderFormOrientations
          form={form}
          onFinish={onFinish}
          young_capital={young_capital}
          other={other}
          exact_structure={exact_structure}
          t={t}
        />
      );
  }
};
