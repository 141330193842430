import { default as OrientationsActions, types } from "./actions";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { OrientationsService, TagsService } from "../../services";
import { LoaderActions } from "../loader";
import i18next from "i18next";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { get } from "lodash";
import { TagsActions } from ".";
import { ROUTES } from "../../core/router";
import { history } from "../../core/history";

function* getTags({ carrefour_id }) {
  const [error, response] = yield call(TagsService.getTags, { carrefour_id });
  if (response) {
    yield put(TagsActions.getTagsSuccess(get(response, "tags", [])));
    return;
  }
  showError("request_failure", i18next.t);
}

function* tagsDelete({ ids }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(TagsService.deleteTags, ids);
  if (response) {
    yield put(
      TagsActions.tagsDeleteSuccess(
        get(response, ["delete_tags", "returning"], [])
      )
    );
    showSuccess("tags_delete_success", i18next.t);
    yield call(history.push, ROUTES.TAGS);
    yield put(LoaderActions.loaded());
    return;
  }
  showError("tags_delete_failed", i18next.t);
  yield put(LoaderActions.loaded());
}

function* insertTagsRequest({ data }) {
  yield put(LoaderActions.loading());
  const { current_user } = yield select(state => state.users);
  const { carrefour_id } = current_user;
  data.carrefour_id = carrefour_id;
  const [error, response] = yield call(TagsService.tagsCreateRequest, data);
  if (response) {
    showSuccess("tags_create_success", i18next.t);
    yield call(history.push, ROUTES.TAGS);
    yield put(LoaderActions.loaded());
    return;
  }
  showError("tags_create_failed", i18next.t);
  yield put(LoaderActions.loaded());
}

function* updateTagsRequest({ id, data }) {
  yield put(LoaderActions.loading());
  const { current_user } = yield select(state => state.users);
  const { carrefour_id } = current_user;
  const [error, response] = yield call(TagsService.updateTags, {
    id: id,
    data: { ...data, carrefour_id: carrefour_id }
  });
  if (response) {
    showSuccess("tags_update_success", i18next.t);
    yield call(history.push, ROUTES.TAGS);
    yield put(LoaderActions.loaded());
    return;
  }
  showError("tags_update_failed", i18next.t);
  yield call(history.push, ROUTES.TAGS);
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_TAGS_REQUEST, getTags),
  takeLatest(types.TAGS_DELETE_REQUEST, tagsDelete),
  takeLatest(types.INSERT_TAGS_REQUEST, insertTagsRequest),
  takeLatest(types.UPDATE_TAGS_REQUEST, updateTagsRequest)
];
