import { encode } from "base-64";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { AuthenticationService } from "../../services";
import { CommonActions } from "../common";
import { LoaderActions } from "../loader";
import { default as AuthActions, types } from "./actions";
import i18next from "i18next";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { get } from "lodash";
import { history } from "../../core/history";
import { ROUTES } from "../../core/router";
import { UsersActions } from "../users";

function* login({ username, password }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(AuthenticationService.login, {
    username,
    password
  });
  if (get(response, ["request", "responseURL"], "") !== "") {
    const url = get(response, ["request", "responseURL"], "");
    const splt = url.split("=");
    const code = splt[1];
    const [tokenError, tokenResponse] = yield call(
      AuthenticationService.getToken,
      {
        username,
        password,
        code
      }
    );
    if (tokenResponse && tokenResponse.data.access_token) {
      const token = get(tokenResponse, ["data", "access_token"], "");
      const token_type = get(tokenResponse, ["data", "token_type"], "");
      const refresh_token = get(tokenResponse, ["data", "refresh_token"], "");

      const authorization = encode(username + ":" + password);

      yield put(
        AuthActions.loginSuccess(
          token_type,
          token,
          refresh_token,
          authorization
        )
      );
      yield put(UsersActions.getCurrentUserRequest(username));
    }
  }

  yield put(LoaderActions.loaded());
  if (error) {
    yield put(AuthActions.loginFailure());
    showError("login_failure", i18next.t);
    return;
  }
  showSuccess("login_success", i18next.t);
  yield call(history.push, ROUTES.DASHBOARD);
}

/**
 * Warning: This code is not used. The current refresh action is in middleware
 * @returns {Generator<*, string[]|*[], ?>}
 */
function* refresh() {
  const { refresh_token, authorization } = yield select(state => state.auth);
  const [tokenError, tokenResponse] = yield call(
    AuthenticationService.refreshToken,
    {
      authorization,
      refresh_token
    }
  );
  if (tokenResponse && tokenResponse.data.access_token) {
    const token = get(tokenResponse, ["data", "access_token"], "");
    const token_type = get(tokenResponse, ["data", "token_type"], "");
    const refresh_token = get(tokenResponse, ["data", "refresh_token"], "");
    yield put(
      AuthActions.loginSuccess(token_type, token, refresh_token, authorization)
    );
    return [null, `${token_type} ${token}`];
  } else return [tokenError, null];
}

function* logout() {
  yield put(CommonActions.resetReducers());
  yield call(history.push, ROUTES.LOGIN);
}

function* forgotPwd(data) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(AuthenticationService.forgotPwd, data);
  if (response) {
    showSuccess("forgot_pwd_success", i18next.t);
    yield call(history.push, ROUTES.LOGIN);
    yield put(LoaderActions.loaded());
    return;
  }
  showError("forgot_pwd_failure", i18next.t);
  yield put(LoaderActions.loaded());
}

function* validateModalFirstConnectionRequest({ id, password }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(AuthenticationService.changePassword, {
    user_id: id,
    password
  });
  if (response) {
    yield put(
      UsersActions.getCurrentUserSuccess(
        get(response, ["data", "response"], {})
      )
    );
    showSuccess("reset_password_success", i18next.t);
    yield put(LoaderActions.loaded());
    return;
  }
  showError("reset_password_failure", i18next.t);
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.LOGIN_REQUEST, login),
  takeLatest(types.LOGIN_REFRESH, refresh),
  takeLatest(types.FORGOT_PWD_REQUEST, forgotPwd),
  takeLatest(types.LOGOUT, logout),
  takeLatest(
    types.VALIDATE_MODAL_FIRST_CO_REQUEST,
    validateModalFirstConnectionRequest
  )
];
