import { EVENT_TYPES } from "../../../utils/eventFields";

export const fields = (beneficiaries, registered, t) => {
  return [
    { key: "name", title: "event_columns_name", required: true },
    {
      key: "type",
      title: "event_columns_type",
      placeholder: t("event_columns_type_placeholder"),
      component: "select",
      required: true,
      args: EVENT_TYPES
    },
    {
      key: "start_date",
      title: "event_columns_duration",
      component: "datepicker",
      required: true
    },
    {
      key: "manager",
      title: "event_columns_manager",
      required: true,
      error: t("event_fields_email_error_format"),
      validator: item => {
        const ret = !item ? true : item.match(/\S+@\S+\.\S+/g);
        return ret;
      }
    },
    {
      key: "registered",
      title: "event_columns_registered",
      placeholder: t("event_columns_placeholder_registered"),
      component: "select",
      mode: "multiple",
      args: beneficiaries
    },
    {
      key: "participant",
      title: "event_columns_participant",
      placeholder: t("event_columns_placeholder_participant"),
      component: "select",
      mode: "multiple",
      args: registered
    },
    {
      key: "description",
      title: "event_fields_description",
      component: "textarea"
    },
    { key: "website_link", title: "event_fields_website_link" },
    {
      key: "permanence_provided_CJC_actor",
      title: "events_permanence_provided_CJC_actor",
      condition_type: "Permanence individuelle",
      component: "checkbox",
      valuePropName: "checked"
    }
  ];
};
