import React, { useState, useEffect } from "react";
import { Button, Row } from "antd";
import { actionsRender } from "./actionRender";
import { DataTable } from "../../components";
import { columns } from "./columnsTable";
import { useTranslation } from "react-i18next";
import { VerticalAlignTopOutlined } from "@ant-design/icons";
import { onScrollTop } from "../../../utils/scrollTop";
import "./style.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { EventsActions } from "../../../redux/events";

export const EventTable = ({ data, selectedRowKeys, setSelectedRowKeys }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const { current, pageSize, sorterFilter } = useSelector(
    state => state.events
  );

  const onPaginationChange = (current, pageSize, record, sorterFilter) => {
    dispatch(
      EventsActions.changeTablePagesize(current, pageSize, sorterFilter)
    );
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const handleOnActionRender = () => {
    return actionsRender(handleReset, onDelete, selectedRowKeys, t);
  };

  const onModify = id => {
    navigateTo(`/event/${id}`);
  };

  const onDelete = ids => {
    dispatch(EventsActions.eventsDeleteRequest(ids));
    setSelectedRowKeys([]);
  };

  return (
    <div>
      <DataTable
        items={data}
        columns={columns({onModify, onDelete, sorterFilter, t})}
        actionsRender={handleOnActionRender}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
        current={current}
        pageSize={pageSize}
        onPaginationChange={onPaginationChange}
      />
      <Row className="user-table-button-container">
        <Button
          type="primary"
          shape="circle"
          icon={<VerticalAlignTopOutlined />}
          onClick={onScrollTop}
        />
      </Row>
    </div>
  );
};
