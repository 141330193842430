import React, { useState } from "react";
import { Collapse, Space } from "antd";
import { useTranslation } from "react-i18next";
import "../style.css";
import { ProjectFilters } from "./projectFilters";
import { BeneficiariesFilters } from "./beneficiariesFIlters";
import { map } from "lodash";
import {
  beneficiariesFilters,
  projectFilters,
  tagsFilters,
  orientationsFilters
} from "./filterList";
import { UndoOutlined } from "@ant-design/icons";
import { FileActiveFilters } from "./fileActiveFilters";
import { OrientationsFilters } from "./orientationsFilters";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BeneficiariesActions } from "../../../../redux/beneficiaries";
import { ROUTES } from "../../../../core/router";

const { Panel } = Collapse;

export const Filters = ({
  filters,
  tags,
  beneficiaries,
  handleFilterClick
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openFilters = object => {
    return map(object, item => {
      if (item !== undefined) return true;
    }).includes(true);
  };

  const resetFilters = () => {
    dispatch(BeneficiariesActions.resetFilters());
  };

  return (
    <Collapse
      defaultActiveKey={openFilters(Object.values(filters)) ? ["1"] : null}
      style={{ position: "relative" }}
    >
      <button id={"logout"} className="button-reset" onClick={resetFilters}>
        <UndoOutlined />
      </button>
      <Panel header={t("dashboard_beneficiaries_filters")} key="1">
        <Space direction="vertical">
          <Collapse
            defaultActiveKey={
              openFilters(Object.values({ ...beneficiariesFilters(filters) }))
                ? ["1"]
                : null
            }
            style={{ width: "100%" }}
          >
            <Panel
              header={t("dashboard_beneficiaries_filters_beneficiaries")}
              key="1"
            >
              <BeneficiariesFilters
                filters={filters}
                beneficiaries={beneficiaries}
                handleFilterClick={handleFilterClick}
              />
            </Panel>
          </Collapse>
          <Collapse
            defaultActiveKey={
              openFilters(Object.values({ ...projectFilters(filters) }))
                ? ["2"]
                : null
            }
          >
            <Panel
              header={t("dashboard_beneficiaries_filters_project")}
              key="2"
            >
              <ProjectFilters
                filters={filters}
                beneficiaries={beneficiaries}
                handleFilterClick={handleFilterClick}
              />
            </Panel>
          </Collapse>
          <Collapse
            defaultActiveKey={
              openFilters(Object.values({ ...tagsFilters(filters) }))
                ? ["3"]
                : null
            }
          >
            <Panel
              header={t("dashboard_beneficiaries_filters_fil_active")}
              key="3"
            >
              <FileActiveFilters
                filters={filters}
                tags={tags}
                handleFilterClick={handleFilterClick}
              />
            </Panel>
          </Collapse>
          <Collapse
            defaultActiveKey={
              openFilters(Object.values({ ...orientationsFilters(filters) }))
                ? ["4"]
                : null
            }
          >
            <Panel
              header={t("dashboard_beneficiaries_filters_orientations")}
              key="4"
            >
              <OrientationsFilters
                filters={filters}
                beneficiaries={beneficiaries}
                handleFilterClick={handleFilterClick}
              />
            </Panel>
          </Collapse>
        </Space>
      </Panel>
    </Collapse>
  );
};
