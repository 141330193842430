export const fields = (emailRequest, emailLoading, t) => {
  return [
    { key: "firstname", title: "user_create_form_firstname", required: true },
    { key: "lastname", title: "user_create_form_lastname", required: true },
    {
      key: "email",
      title: "user_create_form_email",
      required: true,
      args: emailLoading,
      error: t("user_create_user_form_email_format"),
      errorMail: t("user_create_user_form_email_not_available"),
      validator: item => {
        const ret = !item ? true : item.match(/\S+@\S+\.\S+/g);
        if (item && ret) {
          emailRequest(item);
        }
        return ret;
      }
    },
    {
      key: "isAdmin",
      title: "user_create_form_administrator",
      component: "checkbox",
      valuePropName: "checked"
    }
  ];
};
