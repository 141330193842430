import React from "react";
import {DeleteButton, MailButton} from "../../components/button";
import { Divider } from "antd";

export const columns = ({resendRequest, onDelete, sorterFilter, t}) => [
  {
    title: t("users_table_firstame"),
    dataIndex: "firstname",
    align: "center",
    sorter: (a, b) => a.firstname.localeCompare(b.firstname),
    sortOrder: sorterFilter?.field === "firstname" ? sorterFilter?.order : null,
    render: text => <span>{text}</span>
  },
  {
    title: t("users_table_lastname"),
    dataIndex: "lastname",
    align: "center",
    sorter: (a, b) => a.lastname.localeCompare(b.lastname),
    sortOrder: sorterFilter?.field === "lastname" ? sorterFilter?.order : null,
    render: text => <span>{text}</span>
  },
  {
    title: t("users_table_email"),
    dataIndex: "email",
    align: "center",
    sorter: (a, b) => a.email.localeCompare(b.email),
    sortOrder: sorterFilter?.field === "email" ? sorterFilter?.order : null,
    render: text => <span>{text}</span>
  },
  {
    title: t("users_table_actions"),
    key: "action",
    width: 100,
    render: item => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <MailButton
              title={t("user_confirm_resend")}
              resend={() => resendRequest(item.id)}
          />
          <Divider type="vertical" />
          <DeleteButton deletion={() => onDelete([item && item.id])} />
        </div>
      );
    }
  }
];
