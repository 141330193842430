import React from "react";
import "./style.css";
import { AuthLayout } from "../../layouts/authLayout";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ExtraLayout } from "./extraLayout";
import { useSelector, useDispatch } from "react-redux";
import { UsersActions } from "../../../redux/users";
import { TagForm } from "./tagForm";
import { TagsActions } from "../../../redux/tags";

export const TagCreate = () => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onValidate = value => {
    if (!values) {
      dispatch(TagsActions.insertTagsRequest(value));
    } else {
      dispatch(TagsActions.updateTagsRequest(id, value));
    }
  };

  var url = window.location.hash;
  var id = parseInt(url.substring(url.lastIndexOf("/") + 1));

  const { tags } = useSelector(state => state.tags);

  const values = tags.find(item => item.id === id);

  return (
    <AuthLayout
      title={t("tags_create_tag_management_title")}
      current={"4"}
      extra={ExtraLayout(form, navigateTo, t)}
    >
      <TagForm onValidate={onValidate} form={form} values={values} />
    </AuthLayout>
  );
};
