export default {
  orientations: `
    query orientations {
        orientations {
          young_capital
          name
          id
        }
        exact_structure {
          id
          name
          orientations_id
        }
      }`
};
