import React from "react";
import { Modal } from "antd";

const VerticallyCenteredModal = ({
  getTitle,
  isOpen,
  setIsOpen,
  onValidate,
  RenderChildren
}) => {
  const title = getTitle();

  return (
    <Modal
      title={title}
      centered
      visible={isOpen}
      onOk={onValidate}
      onCancel={() => setIsOpen(false)}
    >
      <RenderChildren />
    </Modal>
  );
};

export default VerticallyCenteredModal;
