import React from "react";
import { Button, Form, Image, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AuthActions } from "../../../../redux/auth";
import "./style.css";
import logo from "../../../../assets/images/logo.png";

export const ForgotPwd = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onFinish = ({ email }) => {
    dispatch(
      AuthActions.forgotPwdRequest({
        email: email,
        redirect_url: `${window.location.origin}/#/`
      })
    );
  };

  return (
    <section id="allForm">
      <Image className={"login-logo"} alt="logo" preview={false} src={logo} />
      <Form
        name="forgot_pwd"
        className="forgot-pwd-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: t("forgot_pwd_empty_email") }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t("forgot_pwd_email")}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="forgot-pwd-form-button"
          >
            <p>{t("forgot_pwd_reset_pwd")}</p>
          </Button>
        </Form.Item>
        {t("forgot_pwd_already_have_account")}{" "}
        <a href="/#/">
          <p className="forgot-pwd-form-button-text">{` ${t(
            "forgot_pwd_login"
          )}`}</p>
        </a>
      </Form>
    </section>
  );
};
