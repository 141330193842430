import React from "react";
import "./style.css";
import { AuthLayout } from "../../layouts/authLayout";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ExtraLayout } from "./extraLayout";
/* import { UserForm } from "./userForm"; */
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { EventForm } from "./eventForm";
import { EventsActions, EventsSelector } from "../../../redux/events";
import { BeneficiariesSelector } from "../../../redux/beneficiaries";
import { getDifference } from "../../../utils/genericFunction";

export const EventCreate = () => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { current_user } = useSelector(state => state.users);
  const beneficiaries = useSelector(BeneficiariesSelector.beneficiariesList);

  const { carrefour_id } = current_user;

  var url = window.location.hash;
  var id = url.substring(url.lastIndexOf("/") + 1);

  const values = useSelector(
    EventsSelector.events_create(parseInt(id), beneficiaries)
  );

  const onValidate = value => {
    if (!values) {
      dispatch(EventsActions.createEventsRequest(value, carrefour_id));
    } else {
      const registered = getDifference(value.registered, values.registered);
      const participant = getDifference(value.participant, values.participant);
      value.registered = registered;
      value.participant = participant;
      dispatch(
        EventsActions.updateEventsRequest(parseInt(id), value, carrefour_id)
      );
    }
  };

  return (
    <AuthLayout
      title={t("event_create_management_title")}
      current={"2"}
      extra={ExtraLayout(form, navigateTo, t)}
    >
      <EventForm onValidate={onValidate} form={form} values={values} />
    </AuthLayout>
  );
};
