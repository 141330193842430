import React from "react";
import { Collapse } from "antd";
import { DropDownFilter } from "../../../components";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../../utils/genericFunction";
import { uniq, compact } from "lodash";
import {
  PROJECT_MATURITY,
  PROJECT_ACTIVITY_SECTOR
} from "../../../../utils/beneficiariesFields";
import "../style.css";

export const ProjectFilters = ({
  filters,
  beneficiaries,
  handleFilterClick
}) => {
  const { t } = useTranslation();

  const getAllProjectName = () => {
    return compact(
      uniq(
        beneficiaries.map(item => {
          return item.project_name && capitalizeFirst(item.project_name);
        })
      )
    );
  };

  return (
    <div className={"filters_container"}>
      <div className={"filters_group_container"}>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.project_name}
            array={getAllProjectName() || []}
            filterKey={"project_name"}
            handleFilterClick={handleFilterClick}
            placeholder={t("dashboard_beneficiaries_filters_project_name")}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.project_maturity}
            array={PROJECT_MATURITY}
            filterKey={"project_maturity"}
            handleFilterClick={handleFilterClick}
            placeholder={t("dashboard_beneficiaries_filters_project_maturity")}
            width={450}
          />
        </div>
      </div>
      <div className={"filters_group_container"}>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.project_activity_sector}
            array={PROJECT_ACTIVITY_SECTOR}
            filterKey={"project_activity_sector"}
            handleFilterClick={handleFilterClick}
            placeholder={t(
              "dashboard_beneficiaries_filters_project_activity_sector"
            )}
            width={450}
          />
        </div>
      </div>
    </div>
  );
};
