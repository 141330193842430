import { authenticatedMutation, authenticatedQuery } from "./middleware";
import tagsQueries from "./GraphQLRequest/tagsQueries";
import tagsMutations from "./GraphQLRequest/tagsMutations";

export default {
  getTags: ({ carrefour_id }) =>
    authenticatedQuery(tagsQueries.tags, {}, { carrefour_id }),
  deleteTags: ids =>
    authenticatedMutation(tagsMutations.deleteTags, {}, { ids }),
  tagsCreateRequest: data =>
    authenticatedMutation(tagsMutations.insertTags, {}, { data }),
  updateTags: ({ id, data }) =>
    authenticatedMutation(tagsMutations.updateTags, {}, { id, data })
};
