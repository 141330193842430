import React from "react";
import { Collapse } from "antd";
import { DropDownFilter } from "../../../components";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../../utils/genericFunction";
import { uniq, compact } from "lodash";
import {
  PROJECT_MATURITY,
  PROJECT_ACTIVITY_SECTOR,
  TYPE_OBSTACLES_ENCOUNTRED
} from "../../../../utils/beneficiariesFields";
import "../style.css";
import { SelectTags } from "../../../components/selectTags";

export const FileActiveFilters = ({ filters, tags, handleFilterClick }) => {
  const { t } = useTranslation();

  const onChange = e => {
    handleFilterClick("tags", e);
  };

  return (
    <div className={"filters_container"}>
      <SelectTags tags={tags} onChange={onChange} value={filters.tags} />
    </div>
  );
};
