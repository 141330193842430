import React, { useEffect, useMemo } from "react";
import { Form, Input, Modal, Checkbox, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { pick } from "lodash";
import { components } from "./formComponents";
import { fields } from "./formFields";
import { useTranslation } from "react-i18next";
import { AuthActions } from "../../../redux/auth";

export const FirstConnectionModal = ({
  renewPwd,
  strings,
  validateModalFirstConnection
}) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { current_user } = useSelector(state => state.users);

  const { id, renew_pwd } = current_user;

  const { showModal = false } = useMemo(() => {
    return {
      showModal: renew_pwd
    };
  }, [renew_pwd]);

  useEffect(() => {
    form.resetFields();
    return () => {
      form.resetFields();
    };
  });

  const handleOk = () => {
    form.submit();
  };

  const handleSubmit = values => {
    let filter = pick(values, ["password"]);
    filter = { ...filter, id };
    dispatch(AuthActions.validateModalFirstConnectionRequest(filter));
  };

  const requiredRules = ({ required, ...item }) => {
    let ret = {
      message: t("required_field"),
      required
    };
    if (!item.component) ret = { ...ret, whitespace: true };
    return ret;
  };

  const filterFields = useMemo(() => {
    return fields(form, t).filter(({ key }) => {
      return !(!renew_pwd && ["password", "re_password"].includes(key));
    });
  }, [fields(form, t), t, renew_pwd]);

  return (
    <Modal
      title={t("reset_password_modal")}
      visible={showModal}
      closable={false}
      footer={
        <Button id={"validate-modal-FC"} type={"primary"} onClick={handleOk}>
          {t("reset_password_save")}
        </Button>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        {filterFields.map(
          (
            {
              key,
              title,
              label = true,
              validator,
              error,
              component,
              required,
              requiredMark,
              labelText
            },
            index
          ) => (
            <Form.Item
              name={key}
              label={label ? t(title) : null}
              required={required || requiredMark || false}
              rules={[
                requiredRules(filterFields[index]),
                () => ({
                  validator(rule, value) {
                    if (validator && !validator(value))
                      return Promise.reject(error || `${t(title)} incorrect`);
                    return Promise.resolve();
                  }
                })
              ]}
            >
              {components(t)[component || "input"]({ key, title, labelText })}
            </Form.Item>
          )
        )}
      </Form>
    </Modal>
  );
};
