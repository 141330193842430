import { DeleteOutlined } from "@ant-design/icons";
import React from "react";

import ConfirmButton from "./ConfirmButton";

const DeleteButton = ({
  title,
  loading,
  shape,
  deletion,
  onCancel,
  disabled,
  ghost,
  tooltipTitle
}) => {
  return (
    <ConfirmButton
      disabled={disabled}
      confirm
      loading={loading}
      type={"primary"}
      shape={"circle"}
      icon={<DeleteOutlined />}
      onClick={deletion}
      onCancel={onCancel}
      ghost={ghost}
      tooltipTitle={tooltipTitle}
      danger
    />
  );
};

export default DeleteButton;
