export default {
  insertOrientations: `
    mutation insertOrientations($data: orientations_beneficiaries_insert_input!) {
      insert_orientations_beneficiaries_one(object: $data) {
            id
            orientations_id
            beneficiaries_id
            exact_structure_id
            created_at
        }
      }
      `
};
