import React from "react";
import { DeleteButton, MailButton, EditButton } from "../../components/button";
import { Divider } from "antd";

export const columns = ({onModify, onDelete, sorterFilter, t}) => [
  {
    title: t("tags_table_tag"),
    dataIndex: "tag",
    align: "center",
    sorter: (a, b) => a.tag.localeCompare(b.tag),
    sortOrder: sorterFilter?.field === "tag" ? sorterFilter?.order : null,
    render: text => <span>{text}</span>
  },
  {
    title: t("tags_table_color"),
    dataIndex: "color",
    align: "center",
    render: text => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: text
          }}
        />
      </div>
    )
  },
  {
    title: t("tags_table_actions"),
    key: "action",
    width: 100,
    render: item => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <EditButton modify={() => onModify(item.id)} />
          <Divider type="vertical" />
          <DeleteButton deletion={() => onDelete([item && item.id])} />
        </div>
      );
    }
  }
];
