import React from "react";
import { SelectForm } from "../../components";
import "./style.css";

export const SelectFilter = ({
  filters,
  array,
  filterKey,
  placeholder,
  onFilter
}) => {
  return (
    <div className="event-filter-wrapper">
      <SelectForm
        value={filters.type}
        array={array}
        onChange={e => onFilter(filterKey, e)}
        placeholder={placeholder}
      />
    </div>
  );
};
