export default {
  insertBeneficiaries: `
      mutation insertBeneficiaries($data: [beneficiaries_insert_input!]!) {
        insert_beneficiaries(objects: $data) {
            returning {
               id
            }
         }    
      }
    `,
  updateBeneficiaries: `
    mutation updateBeneficiaries($id: Int!, $data: beneficiaries_set_input) {
      update_beneficiaries_by_pk(pk_columns: {id: $id}, _set: $data) {
        id
      }
    }
    `,
  deleteBeneficiaries: `
    mutation deleteBeneficiaries($ids: [Int!]!) {
      delete_beneficiaries(where: { id: { _in: $ids } }) {
        returning {
          id
        }
      }
    }
  `,
  insertBeneficiariesNote: `
    mutation insertBeneficiariesNote($data: notes_insert_input!) {
      insert_notes_one(object: $data) {
        date
        id
        title
        note
      }
    }
  `,
  insertBeneficiariesTags: `
    mutation insertBeneficiariesTags($data: tags_beneficiaries_insert_input!) {
      insert_tags_beneficiaries_one(object: $data) {
        id
      }
    }
  `,
  deleteBeneficiariesTags: `
    mutation deleteBeneficiariesEvents($ids: [Int!]!, $id: Int!) {
      delete_tags_beneficiaries(where: { beneficiaries_id: { _in: $ids }, _and: {tags_id: {_eq: $id}}}) {
        returning {
          id
        }
      }
    }`
};
