import React from "react";
import { Input, Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export const components = t => {
  return {
    textarea: ({ key, title }) => (
      <TextArea id={key} placeholder={`${t(title)}`} />
    ),
    input: ({ key, title, args }) => (
      <Input
        id={key}
        placeholder={`${t(title)}`}
        maxLength={50}
        suffix={args ? <LoadingOutlined /> : <span />}
      />
    ),
    checkbox: ({ key, title }) => <Checkbox id={key}>{t(title)}</Checkbox>
  };
};
