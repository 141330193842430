import React from "react";
import "./style.css";
import { AuthLayout } from "../../layouts/authLayout";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ExtraLayout } from "./extraLayout";
import { UserForm } from "./userForm";
import { useSelector, useDispatch } from "react-redux";
import { UsersActions } from "../../../redux/users";

const values = [];

export const UserCreate = () => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    current_user,
    available: emailAvailable,
    loading: emailLoading
  } = useSelector(state => state.users);

  const { carrefour_id } = current_user;

  const onValidate = value => {
    dispatch(UsersActions.usersCreateRequest(value, carrefour_id));
  };

  return (
    <AuthLayout
      title={t("user_create_user_management_title")}
      current={"3"}
      extra={ExtraLayout(emailLoading, form, navigateTo, t)}
    >
      <UserForm
        onValidate={onValidate}
        form={form}
        values={values}
        emailLoading={emailLoading}
        emailAvailable={emailAvailable}
      />
    </AuthLayout>
  );
};
