import { default as UsersActions, types } from "./actions";
import { call, put, takeLatest, debounce } from "redux-saga/effects";
import { UsersService } from "../../services";
import { LoaderActions } from "../loader";
import i18next from "i18next";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { get } from "lodash";
import { history } from "../../core/history";
import { ROUTES } from "../../core/router";

function* getUsersRequest({ carrefour_id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(UsersService.getUsers, {
    carrefour_id
  });
  if (response) {
    yield put(UsersActions.getUsersSuccess(get(response, "users", [])));
    yield put(LoaderActions.loaded());
    return;
  }
  showError("request_failure", i18next.t);
  yield put(LoaderActions.loaded());
}

function* getCurrentUser({ username }) {
  const [error, response] = yield call(UsersService.getCurrent, {
    email: username
  });
  if (response) {
    yield put(
      UsersActions.getCurrentUserSuccess(get(response, ["users", 0], {}))
    );
    yield put(LoaderActions.loaded());
    return;
  }
  showError("request_failure", i18next.t);
  yield put(LoaderActions.loaded());
}

function* usersCreateRequest({ user, carrefour_id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(UsersService.createUser, {
    user,
    carrefour_id
  });
  if (response) {
    showSuccess("user_create_success", i18next.t);
    showSuccess("user_create_send_success", i18next.t);
    yield call(history.push, ROUTES.USERS);
  } else if (
    get(error, ["response", "data", "errors"], "mail error") === "mail error"
  ) {
    showSuccess("user_create_success", i18next.t);
    showError("user_create_send_failed", i18next.t);
    yield call(history.push, ROUTES.USERS);
  } else if (error) {
    showError("user_create_failed", i18next.t);
    showError("user_create_send_failed", i18next.t);
  }
  yield put(LoaderActions.loaded());
}

function* userDeleteRequest({ ids }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(UsersService.deleteUser, ids);
  if (response) {
    yield put(
      UsersActions.usersDeleteSuccess(
        get(response, ["delete_users", "returning"], [])
      )
    );
    showSuccess("user_delete_success", i18next.t);
    yield call(history.push, ROUTES.USERS);
    yield put(LoaderActions.loaded());
    return;
  }
  showError("user_delete_failed", i18next.t);
  yield put(LoaderActions.loaded());
}

function* emailRequest({ email }) {
  yield put(UsersActions.emailLoading(true));
  const [error, response] = yield call(UsersService.emailRequest, email);
  if (response) {
    yield put(
      UsersActions.emailSuccess(get(response, ["data", "available"], false))
    );
  }
  yield put(UsersActions.emailLoading(false));
}

function* resendRequest({ id }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(UsersService.resend, id);
  if (response) {
    showSuccess("user_send_success", i18next.t);
    yield put(LoaderActions.loaded());
    return;
  }
  showError("user_send_failed", i18next.t);
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_USERS_REQUEST, getUsersRequest),
  takeLatest(types.GET_CURRENT_USER_REQUEST, getCurrentUser),
  takeLatest(types.USERS_CREATE_REQUEST, usersCreateRequest),
  takeLatest(types.USERS_DELETE_REQUEST, userDeleteRequest),
  takeLatest(types.RESEND_REQUEST, resendRequest),
  debounce(500, types.EMAIL_REQUEST, emailRequest)
];
