import React from "react";
import "./loader.css";
import { useSelector } from "react-redux";

export const GlobalLoader = () => {
  const { displayed } = useSelector(state => state.loader);
  return (
    <div className={`loader-wrapper ${displayed ? "loading" : "loaded"}`}>
      <div className={"folding-cube"}>
        <div className={"cube1 cube"} />
        <div className={"cube2 cube"} />
        <div className={"cube4 cube"} />
        <div className={"cube3 cube"} />
      </div>
    </div>
  );
};
