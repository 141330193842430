import { authenticatedMutation, authenticatedQuery } from "./middleware";
import orientationsQueries from "./GraphQLRequest/orientationsQueries";
import orientationsMutations from "./GraphQLRequest/orientationsMutations";

export default {
  getOrientations: () =>
    authenticatedQuery(orientationsQueries.orientations, {}, {}),
  insertOrientations: data =>
    authenticatedQuery(orientationsMutations.insertOrientations, {}, { data })
};
