import React, { useEffect, useState } from "react";
import { Card, Divider } from "antd";
import { SelectTags } from "../../../components/selectTags";
import { useTranslation } from "react-i18next";
import "../style.css";

export const FileActiveCard = ({
  tags,
  values,
  fileActive,
  setFileActive,
  onChange
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (values && values.tags_beneficiaries.length > 0)
      setFileActive(
        values.tags_beneficiaries.map(item =>
          tags.find(tag => tag.value === item.tags_id)
        )
      );
  }, [values && values.tags_beneficiaries]);

  return (
    <Card
      className="fileactive-card-container"
      style={{ margin: 25, width: "90%" }}
    >
      <Divider orientation="left">{t("tags_card_title")}</Divider>
      <SelectTags tags={tags} onChange={onChange} value={fileActive} />
    </Card>
  );
};
