import React, { useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./style.css";

export const VerticalLine = ({
  array = [],
  RenderColor,
  RenderIcon,
  RenderItem
}) => {
  return (
    <VerticalTimeline lineColor="#000" layout={"1-column-left"}>
      {array.map((item, index) => (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          key={`vertical-timeline-element${index}`}
          contentStyle={{ minWidth: 700 }}
          iconStyle={RenderColor(item.type)}
          position={"right"}
          icon={RenderIcon(item.type)}
        >
          <RenderItem item={item} type={item.type} />
        </VerticalTimelineElement>
      ))}
    </VerticalTimeline>
  );
};
