import { get } from "lodash";
import moment from "moment";

export const filterList = (filters, list, orientations) => {
  const { young_capital, other, exact_structure } = orientations;
  const level_of_study = get(filters, ["level_of_study", "length"], 0);
  const city = get(filters, ["city", "length"], 0);
  const age = get(filters, ["age"], null);
  const labour_market_situation = get(filters, ["labour_market_situation"], "");
  const qpv = get(filters, ["qpv"], "");
  const is_qpv = get(filters, ["is_qpv"], "");
  const reception_modality = get(filters, ["reception_modality"], "");
  const handicapped_worker = get(filters, ["handicapped_worker"], "");
  const obstacles = get(filters, ["obstacles"], "");
  const type_obstacles_encountred = get(
    filters,
    ["type_obstacles_encountred"],
    ""
  );
  const project_name = get(filters, ["project_name", "length"], 0);
  const project_maturity = get(filters, ["project_maturity", "length"], 0);
  const project_activity_sector = get(
    filters,
    ["project_activity_sector", "length"],
    0
  );
  const tags = get(filters, ["tags", "length"], 0);
  const is_young_capital = get(filters, ["is_young_capital"], "");
  const consortium = get(filters, ["consortium"], "");
  const other_filter = get(filters, ["other_filter"], "");
  const exact_structure_filters = get(filters, ["exact_structure_filters"], "");
  const referent = get(filters, ["referent"], 0);
  const received_free_accommodation_carrefour = get(
    filters,
    ["received_free_accommodation_carrefour"],
    ""
  );
  const mentor = get(filters, ["mentor"], "");
  const received_contract_by_contract_window = get(
    filters,
    ["received_contract_by_contract_window"],
    ""
  );

  return list.filter(item => {
    return (
      (level_of_study === 0 ||
        item.level_of_study === filters.level_of_study) &&
      (city === 0 ||
        (item.city &&
          item.city.toLowerCase() === filters.city.toLowerCase())) &&
      (age === null ||
        (moment(item.date_of_birth).isAfter(age[0]) &&
          moment(item.date_of_birth).isBefore(age[1]))) &&
      (labour_market_situation === "" ||
        item.labour_market_situation === filters.labour_market_situation) &&
      (qpv === "" || item.qpv === filters.qpv) &&
      (is_qpv === "" || item.is_qpv === filters.is_qpv) &&
      (reception_modality === "" ||
        item.reception_modality === filters.reception_modality) &&
      (handicapped_worker === "" ||
        item.handicapped_worker === filters.handicapped_worker) &&
      (obstacles === "" || item.obstacles === filters.obstacles) &&
      (type_obstacles_encountred === "" ||
        item.type_obstacles_encountred === filters.type_obstacles_encountred) &&
      (project_name === 0 || item.project_name === filters.project_name) &&
      (project_maturity === 0 ||
        item.project_maturity === filters.project_maturity) &&
      (project_activity_sector === 0 ||
        item.project_activity_sector === filters.project_activity_sector) &&
      (tags === 0 ||
        filters.tags
          .map(tag =>
            item.tags_beneficiaries.some(item => item.tags_id === tag)
          )
          .filter(value => value === true).length >= filters.tags.length) &&
      (is_young_capital === "" ||
        item.orientations_beneficiaries
          .map(i => young_capital.some(e => e.value === i.orientations_id))
          .includes(true) === is_young_capital) &&
      (consortium === "" ||
        item.orientations_beneficiaries
          .map(i => i.orientations_id)
          .includes(young_capital.find(e => e.label === consortium).value)) &&
      (other_filter === "" ||
        item.orientations_beneficiaries
          .map(i => i.orientations_id)
          .includes(other.find(e => e.label === other_filter).value)) &&
      (exact_structure_filters === "" ||
        item.orientations_beneficiaries
          .map(i => i.exact_structure_id)
          .includes(
            exact_structure.find(e => e.label === exact_structure_filters).value
          )) &&
      (referent === 0 || item.referent_id === referent) &&
      (received_free_accommodation_carrefour === "" ||
        item.received_free_accommodation_carrefour ===
          received_free_accommodation_carrefour) &&
      (mentor === "" || item.mentor === mentor) &&
      (received_contract_by_contract_window === "" ||
        item.received_contract_by_contract_window ===
          received_contract_by_contract_window)
    );
  });
};

export const beneficiariesFilters = filters => {
  return {
    city: filters.city,
    age: filters.age,
    handicapped_worker: filters.handicapped_worker,
    is_qpv: filters.is_qpv,
    obstacles: filters.obstacles,
    level_of_study: filters.level_of_study,
    labour_market_situation: filters.labour_market_situation,
    reception_modality: filters.reception_modality,
    qpv: filters.qpv,
    type_obstacles_encountred: filters.type_obstacles_encountred
  };
};

export const projectFilters = filters => {
  return {
    project_name: filters.project_name,
    type_obstacles_encountred: filters.type_obstacles_encountred,
    project_maturity: filters.project_maturity,
    project_activity_sector: filters.project_activity_sector
  };
};

export const tagsFilters = filters => {
  return {
    tags: filters.tags
  };
};

export const orientationsFilters = filters => {
  return {
    is_young_capital: filters.young_capital,
    consortium: filters.consortium,
    other_filter: filters.other_filter,
    exact_structure_filters: filters.exact_structure_filters
  };
};
