import { Select } from "antd";
import React from "react";

const { Option } = Select;

export const DropDownFilter = ({
  array,
  filterKey,
  handleFilterClick,
  placeholder,
  width,
  isBool,
  value
}) => {
  const getBooleenItem = item => {
    return item ? "oui" : "non";
  };

  return (
    <Select
      value={value}
      allowClear
      getPopupContainer={trigger => trigger.parentNode}
      onChange={e => handleFilterClick(filterKey, e)}
      style={{ width: width, marginLeft: 10 }}
      placeholder={placeholder}
      showSearch
    >
      {array.map((item, index) => (
        <Option value={item} key={`${item}-${index}`}>
          {isBool ? getBooleenItem(item) : item}
        </Option>
      ))}
    </Select>
  );
};
