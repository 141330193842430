export default {
  deleteUser: `
    mutation usersDelete($ids: [Int!]!) {
      delete_users(where: { id: { _in: $ids } }) {
        returning {
          id
        }
      }
    }
  `
};
