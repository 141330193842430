export default {
  insertTags: `
    mutation insertTags($data: tags_insert_input!) {
        insert_tags_one(object: $data) {
          id
        }
      }
      `,
  deleteTags: `
    mutation deleteTags($ids: [Int!]!) {
        delete_tags(where: { id: { _in: $ids } }) {
            returning {
                id
            }
        }
    }`,
  updateTags: `
    mutation updateTags($id: Int!, $data: tags_set_input) {
      update_tags_by_pk(pk_columns: {id: $id}, _set: $data) {
        id
      }
    }
    `
};
