import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Button, Space, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthLayout } from "../../layouts";
import { Filter } from "../../components/filter";
import { EventTable } from "./eventTable";
import { DownloadOutlined } from "@ant-design/icons";
import { EventsActions, EventsSelector } from "../../../redux/events";
import { at, keyBy } from "lodash";
import { get } from "lodash";
import { EVENT_TYPES } from "../../../utils/eventFields";
import { SelectFilter } from "../../components/selectFilter/selectFilters";
import "./style.css";

export const Event = ({}) => {
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const { current_user } = useSelector(state => state.users);
  const events = useSelector(EventsSelector.events);
  const { filters, searchValues, searchValuesResult } = useSelector(
    state => state.events
  );
  const { carrefour_id } = current_user;

  useEffect(() => {
    if (carrefour_id) dispatch(EventsActions.getEventsRequest(carrefour_id));
  }, [carrefour_id]);

  useEffect(() => {
    if (events) setValueList(events);
  }, [events]);

  useEffect(() => {
    setValueList(searchValuesResult || events);
  }, [searchValuesResult]);

  const onFilter = (result, value) => {
    if (!result) setReset(!reset);
    dispatch(
      EventsActions.changeSearchValues(
        result,
        value !== undefined ? value : null
      )
    );
  };

  const onTypeFilter = (key, e) => {
    dispatch(EventsActions.changeEventsFilters({ ...filters, [key]: e }));
  };

  const filterList = list => {
    const type = get(filters, ["type"], "");

    return list.filter(item => {
      return type === "" || item.type === type;
    });
  };

  const onCreate = () => {
    navigateTo("/event/add");
  };

  const onExport = () => {
    let eventsToExport =
      selectedRowKeys.length === 0
        ? valueList
        : at(keyBy(valueList, "id"), selectedRowKeys);

    dispatch(EventsActions.exportEvents(eventsToExport, carrefour_id));
  };

  return (
    <AuthLayout current={"2"} bread={"Event:"}>
      <Row justify={"space-between"}>
        <Col span={12}>
          <Space>
            <Filter
              list={events}
              onFilter={onFilter}
              keys={["name", "type", "manager"]}
              searchValues={searchValues}
              placeHolder={t("event_placeholder_filter")}
            />

            <SelectFilter
              filterKey="type"
              filters={filters}
              array={EVENT_TYPES}
              onFilter={onTypeFilter}
              placeholder={t("events_type_filters_placeholders")}
            />
          </Space>
        </Col>
        <Col span={12} className={"flex-end"}>
          <Space>
            <Button
              id={"add_event"}
              key={"create-event"}
              type="primary"
              onClick={onCreate}
            >
              {t("event_add")}
            </Button>
            <Button
              id={"export_event"}
              key={"export-event"}
              type="primary"
              onClick={onExport}
              icon={<DownloadOutlined />}
            >
              {t("beneficiaries_export_xlsx")}
            </Button>
          </Space>
        </Col>
      </Row>
      <EventTable
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        data={filterList(valueList)}
      />
    </AuthLayout>
  );
};
