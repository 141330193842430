import { createSelector } from "reselect";
import { get } from "lodash";

const STORE_NAME = "users";

const referent = createSelector(
  state => get(state, [STORE_NAME, "users"], {}),
  referent =>
    referent.map(item => ({
      value: item.id,
      label: `${item.firstname} ${item.lastname}`
    }))
);

export default {
  referent
};
