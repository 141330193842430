import React from "react";
import { DatePicker } from "antd";
import { DropDownFilter } from "../../../components";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../../utils/genericFunction";
import { uniq, compact } from "lodash";
import {
  LEVEL_OF_STUDY,
  LABOUR_MARKET_SITUATION,
  QPV,
  RECEPTION_MODALITY,
  TYPE_OBSTACLES_ENCOUNTRED
} from "../../../../utils/beneficiariesFields";
import moment from "moment";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import "../style.css";

const { RangePicker } = DatePicker;

export const BeneficiariesFilters = ({
  filters,
  beneficiaries,
  handleFilterClick
}) => {
  const { t } = useTranslation();

  const getAllCity = () => {
    return compact(
      uniq(
        beneficiaries.map(item => {
          return item.city && capitalizeFirst(item.city);
        })
      )
    );
  };

  return (
    <div className={"filters_container"}>
      <div className={"filters_group_container"}>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.city}
            array={getAllCity() || []}
            filterKey={"city"}
            handleFilterClick={handleFilterClick}
            placeholder={t("dashboard_beneficiaries_filters_city")}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <RangePicker
            value={
              filters.age !== undefined &&
              filters.age &&
              filters.age.map(i => moment(i))
            }
            placeholder={[
              t("dashboard_beneficiaries_filters_rangepicker_before"),
              t("dashboard_beneficiaries_filters_rangepicker_after")
            ]}
            locale={locale}
            onChange={e => handleFilterClick("age", e)}
            style={{ width: "98%", marginLeft: "2%" }}
            format={"DD/MM/YYYY"}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.handicapped_worker}
            array={[true, false]}
            filterKey={"handicapped_worker"}
            handleFilterClick={handleFilterClick}
            placeholder={t(
              "dashboard_beneficiaries_filters_handicapped_worker"
            )}
            isBool={true}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.is_qpv}
            array={[true, false]}
            filterKey={"is_qpv"}
            handleFilterClick={handleFilterClick}
            placeholder={t("dashboard_beneficiaries_filters_is_qpv")}
            isBool={true}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.obstacles}
            array={[true, false]}
            filterKey={"obstacles"}
            handleFilterClick={handleFilterClick}
            placeholder={t(
              "dashboard_beneficiaries_filters_handicapped_obstacles"
            )}
            isBool={true}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.received_free_accommodation_carrefour}
            array={[true, false]}
            filterKey={"received_free_accommodation_carrefour"}
            handleFilterClick={handleFilterClick}
            placeholder={t(
              "dashboard_beneficiaries_filters_received_free_accommodation_carrefour"
            )}
            isBool={true}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.mentor}
            array={[true, false]}
            filterKey={"mentor"}
            handleFilterClick={handleFilterClick}
            placeholder={t("dashboard_beneficiaries_filters_mentor")}
            isBool={true}
            width={450}
          />
        </div>
      </div>
      <div className={"filters_group_container"}>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.level_of_study}
            array={LEVEL_OF_STUDY}
            filterKey={"level_of_study"}
            handleFilterClick={handleFilterClick}
            placeholder={t("dashboard_beneficiaries_filters_level_study")}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.labour_market_situation}
            array={LABOUR_MARKET_SITUATION}
            filterKey={"labour_market_situation"}
            handleFilterClick={handleFilterClick}
            placeholder={t(
              "dashboard_beneficiaries_filters_labour_market_situation"
            )}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.reception_modality}
            array={RECEPTION_MODALITY}
            filterKey={"reception_modality"}
            handleFilterClick={handleFilterClick}
            placeholder={t(
              "dashboard_beneficiaries_filters_reception_modality"
            )}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.qpv}
            array={QPV}
            filterKey={"qpv"}
            handleFilterClick={handleFilterClick}
            placeholder={t("dashboard_beneficiaries_filters_qpv")}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.type_obstacles_encountred}
            array={TYPE_OBSTACLES_ENCOUNTRED}
            filterKey={"type_obstacles_encountred"}
            handleFilterClick={handleFilterClick}
            placeholder={t(
              "dashboard_beneficiaries_filters_obastacles_encountred"
            )}
            width={450}
          />
        </div>
        <div className={"dropdown_wrapper"}>
          <DropDownFilter
            value={filters.received_contract_by_contract_window}
            array={[true, false]}
            filterKey={"received_contract_by_contract_window"}
            handleFilterClick={handleFilterClick}
            placeholder={t(
              "dashboard_beneficiaries_filters_received_contract_by_contract_window"
            )}
            isBool={true}
            width={450}
          />
        </div>
      </div>
    </div>
  );
};
