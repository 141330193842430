import React, { useEffect, useState } from "react";
import { Table } from "antd";

import "./dataTable.css";

export const ACTIONS = {
  PUBLISH: "PUBLISH",
  HIDE: "HIDE",
  DELETE: "DELETE",
  RESTORE: "RESTORE"
};

export const DataTable = ({
  items,
  reset,
  loading,
  actionClick,
  itemClick,
  columns,
  actionsRender,
  setSelectedRowKeys,
  selectedRowKeys,
  current,
  pageSize,
  onPaginationChange
}) => {
  const [pagination, setPagination] = useState({
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100", "200", "500"],
    pageSize: pageSize,
    current: current
  });
  const [key, setKey] = useState(0);

  const data = items || [];

  useEffect(() => {
    setPagination({ ...pagination, current: current, pageSize: pageSize });
  }, [current, pageSize]);

  useEffect(() => {
    if (data.length !== key) {
      setKey(data.length);
      setSelectedRowKeys([]);
    }
  }, []);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const handleAction = actionType => {
    if (actionClick) {
      actionClick(actionType, selectedRowKeys);
      setSelectedRowKeys([]);
    }
  };

  const handleOnRowClick = (rest, index) => {
    if (itemClick) itemClick(rest);
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  return (
    <div>
      {selectedRowKeys.length > 0 && actionsRender()}
      <Table
        size={"small"}
        showSorterTooltip={false}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data.map(item => ({ ...item, key: item.id }))}
        loading={loading}
        pagination={pagination}
        onRow={(rest, rowIndex) => {
          return {
            onDoubleClick: event => {
              handleOnRowClick(rest, rowIndex);
            }
          };
        }}
        onChange={(page, record, sorterFilter) => {
          onPaginationChange(page.current, page.pageSize, record, sorterFilter);
        }}
      />
    </div>
  );
};
