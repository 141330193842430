export default {
  beneficiaries: `
    query beneficiaries($carrefour_id: Int!) {
          beneficiaries(where: {carrefour_id: {_eq: $carrefour_id}}, order_by: {created_at: desc}) {
            adress
            carrefour_id
            city
            funding
            exit
            commentary
            created_at
            date_of_birth
            email
            firstname
            genre
            handicapped_worker
            id
            is_qpv
            labour_market_situation
            lastname
            level_of_study
            obstacles
            phone_number
            postal_code
            prescriber
            project_activity_sector
            project_description
            project_maturity
            project_name
            qpv
            reception_modality
            referent_id
            type_obstacles_encountred
            received_free_accommodation_carrefour
            mentor
            mentor_identity
            entry_date
            received_contract_by_contract_window
            tags_beneficiaries {
              tags_id
            }
            orientations_beneficiaries {
              orientations_id
              exact_structure_id
            }
          }
    }`,
  beneficiariesRoadmap: `
    query beneficiariesRoadmap($id: Int!) {
      events(where: {events_beneficiaries: {beneficiaries_id: {_eq: $id}}}) {
        description
        manager
        id
        name
        start_date
        type
        updated_at
        website_link
        end_date
        created_at
      }
      orientations_beneficiaries(where: {beneficiaries_id: {_eq: $id}}) {
        id
        orientations_id
        exact_structure_id
        created_at
      }
      notes(where: {beneficiaries_id: {_eq: $id}}) {
        date
        id
        title
        note
      }
    }
    `,
  checkBeneficiariesEmail: `
    query checkBeneficiariesEmail($email: String!) {
      beneficiaries(where: {email: {_eq: $email}}) {
        id
      }
    }
    `
};
