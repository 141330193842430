export default {
  tags: `
  query tags($carrefour_id: Int!) {
    tags(where: {carrefour_id: {_eq: $carrefour_id}}, , order_by: {id: desc}) {
      color
      id
      tag
    }
  }`
};
