import { authenticatedMutation, authenticatedQuery } from "./middleware";
import eventsQueries from "./GraphQLRequest/eventsQueries";
import eventsMutations from "./GraphQLRequest/eventsMutations";
import { methods } from "../utils";
import { authenticatedService } from "./middleware";

const { POST } = methods;
const routes = {
  exportEvents: process.env.REACT_APP_EXPORT + "/exportEvents"
};

export default {
  getEvents: ({ carrefour_id }) =>
    authenticatedQuery(eventsQueries.events, {}, { carrefour_id }),
  insertEvents: data =>
    authenticatedMutation(eventsMutations.insertEvent, {}, { data }),
  updateEvents: ({ id, data }) =>
    authenticatedMutation(eventsMutations.updateEvents, {}, { id, data }),
  deleteEvents: ids =>
    authenticatedMutation(eventsMutations.deleteEvents, {}, { ids }),
  insertBeneficiariesEvents: data =>
    authenticatedMutation(
      eventsMutations.insertBeneficiariesEvents,
      {},
      { data }
    ),
  deleteBeneficiariesEvents: ({ ids, id }) =>
    authenticatedMutation(
      eventsMutations.deleteBeneficiariesEvents,
      {},
      { ids, id }
    ),
  updateBeneficiariesEvents: ({ ids, id, bool }) =>
    authenticatedMutation(
      eventsMutations.updateBeneficiariesEvents,
      {},
      { ids, id, bool }
    ),
  exportEvents: ({ events, carrefour_id }) =>
    authenticatedService(
      POST,
      routes.exportEvents,
      { events, carrefour_id },
      {},
      {},
      "blob"
    )
};
