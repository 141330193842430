import React, { useState, useEffect } from "react";
import { Row, Button } from "antd";
import { t } from "i18next";
import { AuthLayout } from "../../layouts";
import { TagsTable } from "./tagsTable";
import { Filter } from "../../components/filter";
import { useDispatch, useSelector } from "react-redux";
import { TagsActions } from "../../../redux/tags";
import { useNavigate } from "react-router";
import "./style.css";

export const Tags = ({}) => {
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { current_user } = useSelector(state => state.users);
  const { tags, searchValues, searchValuesResult } = useSelector(
    state => state.tags
  );
  const { carrefour_id } = current_user;

  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  useEffect(() => {
    if (carrefour_id) dispatch(TagsActions.getTagsRequest(carrefour_id));
  }, [carrefour_id]);

  useEffect(() => {
    if (tags) setValueList(tags);
  }, [tags]);

  useEffect(() => {
    setValueList(searchValuesResult || tags);
  }, [searchValuesResult]);

  const onFilter = (result, value) => {
    if (!result) setReset(!reset);
    dispatch(
      TagsActions.changeSearchValues(result, value !== undefined ? value : null)
    );
  };

  const onCreate = () => {
    navigateTo("/tag/add");
  };

  return (
    <AuthLayout current={"4"} bread={"FileActive"}>
      <Row justify={"space-between"}>
        <Filter
          list={tags}
          onFilter={onFilter}
          keys={["tag"]}
          searchValues={searchValues}
          placeHolder={t("tags_placeholder_filter")}
        />
        <div className="separate-filter-div" />
        <div className="create-users-container">
          <Button
            id={"add_tags"}
            key={"create-tags"}
            type="primary"
            onClick={onCreate}
          >
            {t("tags_add_user")}
          </Button>
        </div>
      </Row>
      <TagsTable
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        valueList={valueList}
      />
    </AuthLayout>
  );
};
