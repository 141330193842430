import { createAction } from "../../utils";

// Types
export const types = {
  LOGIN_REFRESH: "LOGIN_REFRESH",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",

  FORGOT_PWD_REQUEST: "FORGOT_PWD_REQUEST",

  LOGOUT: "LOGOUT",

  VALIDATE_MODAL_FIRST_CO_REQUEST: "VALIDATE_MODAL_FIRST_CO_REQUEST",
  VALIDATE_MODAL_FIRST_CO_SUCCESS: "VALIDATE_MODAL_FIRST_CO_SUCCESS",
  VALIDATE_MODAL_FIRST_CO_FAILURE: "VALIDATE_MODAL_FIRST_CO_FAILURE"
};

// Actions
export default {
  loginRefresh: () => createAction(types.LOGIN_REFRESH),

  loginRequest: (username, password) =>
    createAction(types.LOGIN_REQUEST, { username, password }),

  loginSuccess: (token_type, token, refresh_token, authorization) =>
    createAction(types.LOGIN_SUCCESS, {
      token_type,
      token,
      refresh_token,
      authorization
    }),

  loginFailure: () => createAction(types.LOGIN_FAILURE),

  forgotPwdRequest: data => createAction(types.FORGOT_PWD_REQUEST, data),

  logout: () => createAction(types.LOGOUT),

  validateModalFirstConnectionRequest: values =>
    createAction(types.VALIDATE_MODAL_FIRST_CO_REQUEST, values),
  validateModalFirstConnectionSuccess: () =>
    createAction(types.VALIDATE_MODAL_FIRST_CO_SUCCESS),
  validateModalFirstConnectionFailure: () =>
    createAction(types.VALIDATE_MODAL_FIRST_CO_FAILURE)
};
