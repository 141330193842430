import React from "react";
import { Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ColorInput } from "../../components/colorInput";

export const components = t => {
  return {
    input: ({ key, title, args }) => (
      <Input
        id={key}
        placeholder={`${t(title)}`}
        maxLength={50}
        suffix={args ? <LoadingOutlined /> : <span />}
      />
    ),
    colorinput: ({ key, title, args }) => (
      <Input
        id={key}
        disabled={true}
        placeholder={`${t(title)}`}
        maxLength={50}
        suffix={args ? <LoadingOutlined /> : <span />}
      />
    )
  };
};
