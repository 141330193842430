import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Button, Space, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "../../components/filter";
import { AuthLayout } from "../../layouts";
import { BeneficiariesTable } from "./beneficiariesTable";
import { useNavigate } from "react-router-dom";
import { BeneficiariesActions } from "../../../redux/beneficiaries";
import { Filters } from "./filters/filters";
import { filterList } from "./filters/filterList";
import "./style.css";
import { DownloadOutlined } from "@ant-design/icons";
import { at, keyBy } from "lodash";
import { OrientationsActions } from "../../../redux/orientations";
import { TagsSelector, TagsActions } from "../../../redux/tags";
import { SelectFilter } from "../../components/selectFilter/selectFilters";
import { UsersSelector, UsersActions } from "../../../redux/users";

export const Dashboard = () => {
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const {
    beneficiaries,
    filters,
    searchValues,
    searchValuesResult
  } = useSelector(state => state.beneficiaries);
  const { current_user } = useSelector(state => state.users);
  const tags = useSelector(TagsSelector.tags);
  const { carrefour_id } = current_user;
  const { young_capital, other, exact_structure } = useSelector(
    state => state.orientations
  );
  const referent = useSelector(UsersSelector.referent);

  useEffect(() => {
    if (carrefour_id) {
      dispatch(BeneficiariesActions.getBeneficiariesRequest(carrefour_id));
      dispatch(TagsActions.getTagsRequest(carrefour_id));
      dispatch(OrientationsActions.getOrientationsRequest());
      dispatch(UsersActions.getUsersRequest(carrefour_id));
    }
  }, [carrefour_id]);

  useEffect(() => {
    if (beneficiaries) setValueList(beneficiaries);
  }, [beneficiaries]);

  useEffect(() => {
    setValueList(searchValuesResult || beneficiaries);
  }, [searchValuesResult]);

  const onFilter = (result, value) => {
    if (!result) setReset(!reset);
    dispatch(
      BeneficiariesActions.changeSearchValues(
        result,
        value !== undefined ? value : null
      )
    );
  };

  const onCreate = () => {
    navigateTo("/beneficiarie/add");
  };

  const onExport = () => {
    let beneficariesToExport =
      selectedRowKeys.length === 0
        ? filterList(filters, valueList, {
            young_capital,
            other,
            exact_structure
          })
        : at(
            keyBy(
              filterList(filters, valueList, {
                young_capital,
                other,
                exact_structure
              }),
              "id"
            ),
            selectedRowKeys
          );

    dispatch(
      BeneficiariesActions.exportBeneficiaries(
        beneficariesToExport,
        carrefour_id
      )
    );
  };

  const handleFilterClick = (key, e) => {
    dispatch(
      BeneficiariesActions.changeBeneficiariesFilters({ ...filters, [key]: e })
    );
  };

  return (
    <AuthLayout current={"1"}>
      <Filters
        filters={filters}
        tags={tags}
        beneficiaries={beneficiaries}
        handleFilterClick={handleFilterClick}
      />
      <br />
      <Row justify={"space-between"}>
        <Col span={12}>
          <Space>
            <Filter
              list={beneficiaries}
              onFilter={onFilter}
              keys={[
                "firstname",
                "lastname",
                "email",
                "project_description",
                "project_name"
              ]}
              searchValues={searchValues}
              placeHolder={t("users_placeholder_filter")}
            />
            <SelectFilter
              filterKey="referent"
              filters={filters}
              array={referent}
              onFilter={handleFilterClick}
              placeholder={t("beneficiaries_referent_filters_placeholders")}
            />
          </Space>
        </Col>
        <Col span={12} className={"flex-end"}>
          <Space>
            <Button
              id={"add_user"}
              key={"create-beneficiaries"}
              type="primary"
              onClick={onCreate}
            >
              {t("beneficiaries_add_beneficiarie")}
            </Button>
            <Button
              id={"export_user"}
              key={"export-beneficiaries"}
              type="primary"
              onClick={onExport}
              icon={<DownloadOutlined />}
            >
              {t("beneficiaries_export_xlsx")}
            </Button>
          </Space>
        </Col>
      </Row>
      <BeneficiariesTable
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        data={filterList(filters, valueList, {
          young_capital,
          other,
          exact_structure
        })}
      />
    </AuthLayout>
  );
};
