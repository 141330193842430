import React from "react";
import {
  FormOutlined,
  CalendarOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";
import "./style.scss";
import { TYPES } from "./RenderMappers";

export const MultipleChoice = ({ setIsOpen, isOpen, wrapper }) => {
  const eventListener = () => {
    wrapper.classList.toggle("wrapper-open");
  };

  const onTriggerModal = type => {
    setIsOpen({ open: !isOpen, type: type });
  };

  return (
    <div className={"wrapper-container"}>
      <div className={"wrapper"}>
        <button className="add" onClick={eventListener}>
          <svg viewBox="0 0 448 512" width="100" title="plus">
            <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
          </svg>
        </button>
        <button
          className="event"
          title="événement"
          onClick={() => onTriggerModal(TYPES.EVENTS)}
        >
          <CalendarOutlined />
        </button>
        <button
          className="orientation"
          title="orientation"
          onClick={() => onTriggerModal(TYPES.ORIENTATIONS)}
        >
          <UserSwitchOutlined />
        </button>
        <button
          className="note"
          title="note"
          onClick={() => onTriggerModal(TYPES.NOTES)}
        >
          <FormOutlined />
        </button>
      </div>
    </div>
  );
};
