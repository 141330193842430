import React, { useEffect, useState } from "react";
import { Input, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CirclePicker } from "react-color";
import { useTranslation } from "react-i18next";

export const ColorInput = ({ color, handleOnChangeFormValue }) => {
  return (
    <CirclePicker color={color} onChangeComplete={handleOnChangeFormValue} />
  );
};
